import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const ListBox = ({ items, className, style }) => (
  <div
    className={cx(
      "whitespace-pre leading-normal w-56 max-h-200 p-2 text-xxs",
      "bg-mediumShading border rounded shadow-md overflow-y-auto",
      className
    )}
    style={style}
  >
    <span>{items.join(`\n`)}</span>
  </div>
);

ListBox.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ListBox;
