import {
  createRef,
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import App from "next/app";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import {
  init as SentryInit,
  // setContext as setSentryContext,
  configureScope as configureSentryScope,
  captureException,
  // captureMessage,
} from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import NProgress from "nprogress";
// import debounce from "lodash.debounce";
import { ContextProvider } from "pageFiles/App/Context";
import {
  wmjwtCookieMaxAge,
  autoSignOutMinutes,
  reloadOnSignoutHours,
  apiUrl,
  graphqlUri,
  // graphqlProxy,
  PAGE_SIZE_OPTIONS,
  INITIAL_PAGE_SIZE_OPTION,
} from "utils/config";
import Layout from "pageFiles/App/Layout/Layout";
import SignIn from "auth/SignIn";
import { pageInfo, nonMenuPages } from "pageFiles/App/menu";
import { authorized, noop } from "utils/utils";
import "styles/app.css";
// TODO: extract only needed css from the following? Or fetch from cdn? But that would be hard to keep in sync with package updates
import "react-datepicker/dist/react-datepicker.min.css";
import "nprogress/nprogress.css";

// Amplify auth
import { Amplify, Auth, Hub } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import {
  AmplifyAuthenticator,
  // AmplifySignIn,
  AmplifyConfirmSignIn,
} from "@aws-amplify/ui-react";
const MFAModal = dynamic(() => import("auth/MFA"));
const ForgetDeviceModal = dynamic(() => import("auth/ForgetDeviceModal"));
import awsconfig from "auth/aws-exports";
import { IS_CMS_USER_ACTIVE, LOGOUT_WMAXX } from "utils/gqlQueries";

// Apollo setup
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
  ApolloProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
// Link chaining: https://www.apollographql.com/docs/react/api/link/introduction/
const httpLink = new HttpLink({ uri: graphqlUri });

// try https://github.com/newsiberian/apollo-link-token-refresh ??
const authLink = new ApolloLink(async (operation, forward) => {
  try {
    // -> replace with getAuthToken() and remove try/catch
    const session = await Auth.currentSession();
    if (session) {
      operation.setContext(({ headers }) => ({
        headers: { Authorization: `Bearer ${session.accessToken.jwtToken}` },
        ...headers,
      }));
    }
  } catch (getSessionForRequest) {
    console.error({ getSessionForRequest });
  }
  return forward(operation);
});

const retryLink = new RetryLink({
  delay: { max: 8000 },
  attempts: {
    retryIf: (error, _operation) => (error?.statusCode || 0) < 400,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const authError = graphQLErrors?.find(
    (e) => e.errorCode === "AuthenticationError"
  );
  if (authError) {
    if (!authError.message.match(/Missing Bearer token|expired/)) {
      console.error("Apollo auth error: ", authError.message);
      captureException(authError.message, {
        tags: {
          errorType: "Apollo auth error",
          path: authError.path.toString(),
        },
      });
    }
    localSignOut({ apiLogout: false });
  }
  // TODO: Handle authorization error (unauthorized path)
  else if (networkError) {
    // captureException(networkError, { tags: { errorType: "Apollo network error" } });
  } else {
    const errorsString = graphQLErrors
      .map((e) => `${e.errorCode || "Exception"}: ${e.message}`)
      .join("; ");
    console.error("Apollo graphQL error", errorsString);
    captureException(graphQLErrors[0]?.message || "graphQL", {
      tags: {
        errorType: "Apollo graphQL error",
        path: graphQLErrors?.[0]?.path?.join(" > "),
      },
    });
  }
});

const clientOptions = {
  link: from([authLink, retryLink, errorLink, httpLink]),
  cache: new InMemoryCache(), // or custom cache (e.g. apollo-cache-persist)
  defaultOptions: {
    watchQuery: { fetchPolicy: "cache-and-network" }, // for useQuery
    query: { fetchPolicy: "cache-and-network" },
  },
  // connectToDevTools: true // connect even in prod
};
const apolloClient = new ApolloClient(clientOptions);

const isProd = process.env.NEXT_PUBLIC_ENV_NAME === "prod";

let initialTime = new Date().getTime();
let autoSignOutIntervalId = null;
let nextAutoSignOutCheck = null;

const localSignOut = async ({ apiLogout = true } = {}) => {
  console.log("local signout; apiLogout=" + apiLogout);
  clearInterval(autoSignOutIntervalId);

  if (apiLogout) {
    await apolloClient
      .mutate({ mutation: LOGOUT_WMAXX, fetchPolicy: "no-cache" })
      .catch((apiLogoutError) => {
        // console.error({ apiLogoutError: apiLogoutError.message });
      });
  }

  try {
    await Auth.signOut();
  } catch (authSignOutErr) {
    console.error({ authSignOutErr });
  }

  fetch(`${apiUrl}/setJwt`, {
    method: "post",
    body: JSON.stringify({ maxAge: 0 }),
  });

  apolloClient.clearStore();

  if (
    new Date().getTime() - initialTime >
    reloadOnSignoutHours * 60 * 60 * 1000
  ) {
    location.reload(); // to ensure latest version of app
  }
};

SentryInit({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: isProd || process.env.NEXT_PUBLIC_ENV_NAME === "staging",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
  release: process.env.NEXT_BUILD_ID, // defined in next.config.js
  maxBreadcrumbs: 50,
  attachStacktrace: true,
});

// Page transition effects
const mainRef = createRef();

const scrollMainToTop = (/* caller */) => {
  // console.log(`scrollMainToTop: ${caller}`);
  // mainRef.current?.scroll({ top: 0, left: 0, behavior: "smooth" });
  mainRef.current?.scroll(0, 0);
};

NProgress.configure({ showSpinner: false });

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", (url) => {
  scrollMainToTop(`routeChangeComplete (${url})`);
  NProgress.done();
});
Router.events.on("routeChangeError", () => NProgress.done());

Amplify.configure(awsconfig);

const MyApp = ({ Component, pageProps, time }) => {
  const router = useRouter();
  const [alertInfo, setAlertInfo] = useState();

  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();
  const signedIn = authState === AuthState.SignedIn && !!user;

  const [showMFAModal, setShowMFAModal] = useState(null);
  const [showForgetDeviceModal, setShowForgetDeviceModal] = useState(false);

  useEffect(() => {
    // This is needed because onAuthUIStateChange doesn't respond to manual signout
    Hub.listen("auth", (data) => {
      // console.log("hub listen", { data });
      if (data.payload.event === "signOut") {
        setAuthState(AuthState.SignedOut);
        setUser(null);
      }
    });

    return onAuthUIStateChange((nextAuthState, authData) => {
      // console.log("^^^ onAuthUIStateChange - setting user & authState", {
      //   user: authData,
      //   preferredMFA: authData?.preferredMFA,
      //   nextAuthState,
      //   challengeName: authData?.challengeName,
      // });
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  useEffect(() => {
    if (!authState)
      Hub.dispatch("UI Auth", {
        event: "AuthStateChange",
        message: AuthState.SignIn,
      });
  }, [authState]);

  useEffect(() => {
    clearInterval(autoSignOutIntervalId);
    // console.log("useEffect: clearing interval # " + autoSignOutIntervalId);
    if (!signedIn) {
      // console.log("useEffect: not signed in");
      return;
    }

    if (isProd) {
      // The purpose of this route is to enable setting an HttpOnly cookie (wmjwt) that will work for wmaxx.
      // Note: It doesn't work for iframes, so Front plugin can only access wmaxx thru this app's api
      fetch(`${apiUrl}/setJwt`, {
        method: "post",
        body: JSON.stringify({
          jwt: user.signInUserSession.accessToken.jwtToken,
          maxAge: wmjwtCookieMaxAge,
        }),
      });
    }

    nextAutoSignOutCheck =
      new Date().getTime() + autoSignOutMinutes * 60 * 1000;
    // console.log("useEffect: signed in ", {
    //   nextAutoSignOutCheck: new Date(nextAutoSignOutCheck),
    // });

    autoSignOutIntervalId = setInterval(async () => {
      if (new Date().getTime() > nextAutoSignOutCheck) {
        // console.log("^^inside interval: checking isCmsUserActive");
        const { data } = await apolloClient
          .query({
            query: IS_CMS_USER_ACTIVE,
            fetchPolicy: "network-only",
          })
          .catch((isCmsUserActiveError) => {
            console.error({
              isCmsUserActiveError: isCmsUserActiveError.message,
            });
            return {};
          });

        if (data?.isCmsUserActive?.isActive) {
          nextAutoSignOutCheck =
            new Date().getTime() +
            (60 * autoSignOutMinutes - data.isCmsUserActive.timeSinceActive) *
              1000;

          // console.log("inside interval - got new activity data", {
          //   timeSinceActive: data.isCmsUserActive.timeSinceActive,
          //   nextAutoSignOutCheck: new Date(nextAutoSignOutCheck),
          // });
        } else {
          // TODO: modal?

          // console.log(
          //   "inside interval - no activity data, starting auto signout"
          // );
          localSignOut();
        }
      }
    }, 3000);

    // console.log(
    //   "useEffect: signed in; new interval id = " + autoSignOutIntervalId
    // );
  }, [signedIn, user?.signInUserSession?.accessToken.jwtToken]);

  const userGroups = useMemo(
    () =>
      user && !user.challengeName // challengeName (NEW_PASSWORD_REQUIRED / SOFTWARE_TOKEN_MFA) can prevent loading signInUserSession
        ? user.signInUserSession.accessToken.payload["cognito:groups"] || []
        : [],
    [user]
  );

  const userId = useMemo(
    () =>
      (!!user &&
        !user.challengeName &&
        Number(user.signInUserSession.idToken.payload["custom:userId"])) ||
      undefined,
    [user]
  );

  const userName = useMemo(
    () =>
      (!!user &&
        !user.challengeName &&
        user.signInUserSession.idToken.payload.name) ||
      undefined,
    [user]
  );

  useEffect(() => {
    configureSentryScope((scope) => {
      scope.setUser({
        id: userId,
        name: userName,
        groups: userGroups,
      });
    });
  }, [userId, userName, userGroups]);

  // Authorization
  useEffect(() => {
    const path = router.pathname.slice(1);
    const nonWmaxxAllow = [
      "login",
      "unauthorized",
      "front-plugin",
      "get-payment-link",
    ];
    const excludeNonWmaxx =
      !userGroups.includes("Wmaxx") && !nonWmaxxAllow.includes(path);
    if (
      user &&
      userGroups &&
      user.preferredMFA === "SOFTWARE_TOKEN_MFA" && // no userGroups before MFA!
      (!authorized(userGroups, pageInfo.allow[path]) || excludeNonWmaxx)
    ) {
      console.log("unauthorized route");
      // router.replace("/login"); // triggers signOut
      router.replace("/unauthorized");
    }
  }, [user, userGroups, router, router.pathname]);

  const signOut = useCallback(
    ({ global } = {}) => {
      // "global" revokes user's sessions on all devices. Not currently used.
      if (global && user) {
        user.globalSignOut({
          onSuccess: () => console.log("global signout"),
          onFailure: (globalSignOutErr) => console.error({ globalSignOutErr }),
        });
      }
      localSignOut();
    },
    [user]
  );

  useEffect(() => {
    if (router.pathname === "/login") {
      localSignOut({ apiLogout: false });
    }
  }, [router.pathname]);

  useEffect(() => {
    if (!user || showMFAModal === false) return;
    if (user.preferredMFA === "NOMFA" && showMFAModal === null) {
      setShowMFAModal(true);
    } else if (user.preferredMFA === "SOFTWARE_TOKEN_MFA") {
      setShowMFAModal(false);
    }
  }, [user, showMFAModal]);

  const handleForgetDeviceSelection = (forget) => {
    user.getCachedDeviceKeyAndPassword();
    if (forget) {
      user.setDeviceStatusNotRemembered({
        onSuccess: () => console.log("setDeviceStatusNotRemembered"),
        onFailure: (err) =>
          console.error("setDeviceStatusNotRemembered", { err }),
      });
    } else {
      user.setDeviceStatusRemembered({
        onSuccess: () => console.log("setDeviceStatusRemembered"),
        onFailure: (err) => console.error("setDeviceStatusRemembered", { err }),
      });
    }
    setShowForgetDeviceModal(false);
  };

  const handleSubmitTotp = (e, code) => {
    e.preventDefault();
    Auth.verifyTotpToken(user, code)
      .then(() => {
        setAlertInfo();
        Auth.setPreferredMFA(user, "TOTP");
        handleForgetDeviceSelection(false);
        // setShowForgetDeviceModal(true);
        setShowMFAModal(false);
      })
      .catch((err) => {
        setAlertInfo({ text: "The code could not be verified" });
        console.error("verifyTotpToken", { err });
      });
  };

  const refreshOrdersSubmenu = useRef(noop);

  const pageSize = useMemo(
    () =>
      (typeof window === "object" &&
        window.localStorage &&
        parseInt(localStorage.getItem("pageSize"), 10)) ||
      PAGE_SIZE_OPTIONS[INITIAL_PAGE_SIZE_OPTION],
    []
  );
  const [controlledPageSize, setControlledPageSize] = useState(pageSize);

  const handleSetControlledPageSize = (size) => {
    setControlledPageSize(size);
    localStorage?.setItem("pageSize", size);
  };

  // console.log("^^^ app.js render", {
  //   user,
  //   preferredMfa: user?.preferredMFA,
  //   authState,
  //   challengeName: user?.challengeName,
  //   signedIn,
  //   showMFAModal,
  //   showForgetDeviceModal,
  // });

  const showLayout = !pageInfo.hideLayout[router.pathname.slice(1)];

  const _setAlertInfo = useCallback(
    (info) => setAlertInfo(info && { ...info, key: new Date().getTime() }),
    []
  );

  const authenticated =
    signedIn && !showForgetDeviceModal && showMFAModal === false;

  return authenticated ? (
    <ContextProvider
      {...{
        user,
        userId,
        scrollMainToTop,
        refreshOrdersSubmenu,
        controlledPageSize,
        setControlledPageSize: handleSetControlledPageSize,
        setAlertInfo: _setAlertInfo,
        userGroups,
        signOut,
      }}
    >
      <ApolloProvider client={apolloClient}>
        <Layout mainRef={mainRef} alertInfo={alertInfo} showLayout={showLayout}>
          <Component {...pageProps} time={time} />
        </Layout>
      </ApolloProvider>
    </ContextProvider>
  ) : (
    <>
      <Head>
        <link
          rel="preconnect"
          href={process.env.NEXT_PUBLIC_GRAPHQL_URI.replace(
            "/wmaxxapi/graphql",
            ""
          )}
        />
        <title>WatchMaxx Login</title>
      </Head>
      <div
        className="absolute top-0 bottom-0 left-0 right-0 bg-cover bg-gray-200 fade-in"
        style={{
          backgroundImage:
            authState && showLayout
              ? "url(/images/LIV_qiphgs.webp)"
              : undefined,
        }}
      />
      {/* regular alert dialog is not available before login */}
      {alertInfo && (
        <h4
          className="fixed w-64 bg-red-600 text-white z-100 text-center font-bold"
          style={{ right: "50%", transform: "translateX(50%)" }}
        >
          {alertInfo.text}
        </h4>
      )}
      <AmplifyAuthenticator className="p-0">
        {/* We use custom Sign In component so that password manager will work */}
        <SignIn slot="sign-in" authState={authState} showLayout={showLayout} />
        <AmplifyConfirmSignIn
          headerText="Please enter verification code"
          slot="confirm-sign-in"
          user={user}
          handleAuthStateChange={(nextAuthState /* , data */) => {
            if (nextAuthState === AuthState.SignIn) {
              // clicked "Back to Sign-in"
              Hub.dispatch("UI Auth", {
                event: "AuthStateChange",
                message: AuthState.SignIn,
              });
            }
            if (nextAuthState === AuthState.SignedIn)
              setShowForgetDeviceModal(true);
          }}
        />
      </AmplifyAuthenticator>
      {showForgetDeviceModal && (
        <ForgetDeviceModal onSubmit={handleForgetDeviceSelection} />
      )}
      {showMFAModal && (
        <MFAModal user={user} onSubmit={handleSubmitTotp} signOut={signOut} />
      )}
    </>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  //   if (typeof window === "undefined") {
  //     // const orders_submenu = await fetch(`${apiUrl}/orders_submenu`).then((res) =>
  //     //   res.json()
  //     // );
  //     return {
  //       ...appProps,
  //       // orders_submenu: orders_submenu.sections,
  //     };
  //   }

  // Provides a value for pages to use as a key that changes when there is a navigation event (e.g. to reset table or re-fetch data)
  return { ...appProps, time: new Date().getTime() };
};

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  time: PropTypes.number.isRequired,
  // orders_submenu: PropTypes.array,
};

export default MyApp;
