export const wmaxxUrl = process.env.NEXT_PUBLIC_WMAXX_URL;
// export const wmaxxHost = wmaxxUrl.split("/cms")[0];
export const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
export const apiUrl = process.env.NEXT_PUBLIC_API_URL;
export const graphqlUri = process.env.NEXT_PUBLIC_GRAPHQL_URI;
// export const graphqlProxy = process.env.NEXT_PUBLIC_GRAPHQL_PROXY;

export const isDev = process.env.NEXT_PUBLIC_ENV_NAME === "dev";
// export const isStaging = process.env.NEXT_PUBLIC_ENV_NAME === "staging";
export const isProd = process.env.NEXT_PUBLIC_ENV_NAME === "prod";

export const logging = !isProd && false;

// auth
export const wmjwtCookieMaxAge = 60 * 60 * 12;
export const autoSignOutMinutes = 240;
export const reloadOnSignoutHours = 48;

// general
export const ALERT_TIMEOUT = 7000;

// Filters
export const DEBOUNCE_MS = 250;
export const ON_FETCH_DATA_DEBOUNCE_MS = 100;

// Pagination
export const PAGE_SIZE_OPTIONS = [25, 50, 100, 200, 500, 1000];
export const INITIAL_PAGE_SIZE_OPTION = 3; // 200
export const MAX_PAGE_LINKS = 5;
export const INITIAL_ROWS_TO_FETCH_FROM_SERVER = 200;

// Orders
// export const SHIPPING_URGENT_WITHIN_DAYS = 2;

// >> Order statuses
export const VERIFIED_IN_STOCK = "22";
export const REVIEW_OUT_OF_STOCK = "19";
export const VERIFIED_OUT_OF_STOCK = "23";
export const SOURCED = "9";
export const AWAITING_CHARGE = "28";
export const PROCESS_SHIPMENT = "30";
export const PROCESS_SHIPMENT_NAME = "Process Shipment";
// export const MULTIPLE = "34";
// export const MULTIPLE_NAME = "Multiple";
export const PRICE_ALERT = "41";

export const OUT_OF_STOCK_STATUSES = [
  REVIEW_OUT_OF_STOCK,
  VERIFIED_OUT_OF_STOCK,
  SOURCED,
];

// >> Stock statuses
export const inStockMapping = {
  0: { name: "Out of Stock", fixed: 95 },
  1: { name: "In Stock", fixed: 1 },
  2: { name: "1-2 Days (Dropship)", fixed: 3 },
  3: { name: "2-4 Days", fixed: 5 },
  4: { name: "1-2 Weeks", fixed: 7 },
  5: { name: "2-4 Weeks", fixed: 8 },
  6: { name: "4-8 Weeks", fixed: 9 },
  7: { name: "2-4 Months", fixed: 10 },
  10: { name: "4-6 Days", fixed: 6 },
  11: { name: "In Stock (PO/SD)", fixed: 2, disable: true },
  12: { name: "1-2 Days", fixed: 4 },
  30: { name: "In Stock (PO/SD)", fixed: 2 },
  97: { name: "Price & Availability", fixed: 97 },
  98: { name: "New Release", fixed: 98 },
  99: { name: "Discontinued", fixed: 99 },
  100: { name: "Incomplete", fixed: 100 },
  101: { name: "Contact Us", fixed: 101 },
};

export const inStockOptions = Object.entries(inStockMapping)
  .filter(([_, { disable }]) => !disable)
  .map(([num, { name }]) => ({ label: `${num}: ${name}`, value: Number(num) }));

export const orderSources = [
  { value: "amazon", label: "Amazon" },
  { value: "amazonfba", label: "Amazon FBA" },
  { value: "ebay", label: "Ebay" },
  { value: "facebook", label: "Facebook" },
  { value: "google", label: "Google" },
  { value: "groupon", label: "Groupon" },
  { value: "ontimewatch", label: "OntimeWatch" },
  { value: "shopsimon", label: "ShopSimon" },
  { value: "walmart", label: "Walmart" },
  { value: "watchmaxx", label: "Watchmaxx" },
];

// Payment link
export const PAYMENT_LINK_MAX_AMOUNT = 30000;

// Coupons
export const paymentTypes = [
  { value: 1, label: "Credit Card" },
  { value: 2, label: "PayPal" },
  { value: 4, label: "Wire Transfer" },
  { value: 5, label: "Check/Money Order" },
  { value: 11, label: "Affirm" },
  { value: 12, label: "Chrono24" },
  { value: 13, label: "Amazon Pay" },
  { value: 16, label: "Alipay" },
];

// locations
export const LOCATION_DEFAULT_CAPACITY = null;
export const LOCATION_AUTOCOMPLETE_RESULTS = 6;
