import { memo } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import cx from "classnames";
import { wmaxxUrl } from "utils/config";

const SubmenuItem = ({ menuItem, submenuItem, active }) => (
  <li
    key={submenuItem.name}
    className={cx(
      "px-1 pl-10 pr-5 h-12 leading-loose whitespace-no-wrap",
      active
        ? "font-bold text-blue-500 hover:text-blue-400"
        : "text-sidebarText hover:text-white"
    )}
  >
    {submenuItem.path ? (
      <Link href={`/${submenuItem.path}${submenuItem.params || ""}`}>
        {submenuItem.name}
      </Link>
    ) : (
      <a
        href={`${wmaxxUrl}?cmd=${submenuItem.cmd || menuItem.cmd}${
          submenuItem.params || ""
        }`}
      >
        {submenuItem.name}
      </a>
    )}
  </li>
);

SubmenuItem.propTypes = {
  submenuItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cmd: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.string,
  }),
  menuItem: PropTypes.shape({
    cmd: PropTypes.string,
  }),
  active: PropTypes.bool,
};

export default memo(SubmenuItem);
