import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const PageWrapper = ({ title, children, className }) => (
  <div className={cx("px-6", className)}>
    {title && <h4 className="pl-3 pt-4 pb-6 mb-8 border-b">{title}</h4>}
    {children}
  </div>
);

PageWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default PageWrapper;
