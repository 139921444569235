import { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Modal = ({
  className,
  open,
  handleClose,
  title,
  children,
  width = 550,
  minHeight,
  position = "center",
}) => {
  useEffect(() => {
    if (open) document.body.classList.add("prevent-body-scroll");
    return () => document.body.classList.remove("prevent-body-scroll");
  }, [open]);

  return (
    <div
      className={cx(
        "fixed w-full h-full top-0 left-0 flex items-center justify-center z-50 transition-opacity duration-200 leading-normal",
        open
          ? "overflow-x-hidden overflow-y-visible"
          : "opacity-0 pointer-events-none"
      )}
    >
      {open && (
        <>
          {/* overlay */}
          <div
            className="absolute w-full h-full bg-gray-700 bg-opacity-50"
            onClick={handleClose || null}
          />

          {/* box */}
          <div
            className={cx(
              "fixed my-0 mx-auto overflow-y-auto bg-white rounded z-100",
              className
            )}
            style={{
              width,
              top: position === "top" ? "3rem" : undefined,
              minHeight,
              maxHeight: "95vh",
            }}
          >
            {/* X */}
            {handleClose && (
              <div
                className="absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50"
                onClick={handleClose}
              >
                <svg
                  className="fill-current text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </div>
            )}

            <div className="mx-auto container h-auto text-left p-4">
              {title && (
                <div className="flex justify-between items-center pb-2">
                  <p className="mb-2 text-2xl font-bold">{title}</p>
                </div>
              )}

              {children}

              {/* <!--Footer--> */}
              {/* <div className="flex justify-end pt-2">
            <button className="px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2">
              Action
            </button>
            <button
              className="modal-close px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
              onClick={handleClose}
            >
              Close
            </button>
          </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

//   document.onkeydown = function(evt) {
//     evt = evt || window.event
//     var isEscape = false
//     if ("key" in evt) {
//   	isEscape = (evt.key === "Escape" || evt.key === "Esc")
//     } else {
//   	isEscape = (evt.keyCode === 27)
//     }
//     if (isEscape && document.body.classList.contains('modal-active')) {
//   	toggleModal()
//     }
//   };

Modal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  width: PropTypes.number,
  minHeight: PropTypes.number,
  position: PropTypes.oneOf(["top", "center"]),
};

export default Modal;
