import { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import SelectComponent from "react-select";
import CreatableSelect from "react-select/creatable";

/**
 *
 * @param {array} options [{ label: "test@test.test", value: 4 }, { label: "a@b.c", value: 5 },]
 * @param {array} value [{ label: "a@b.c", value: 5 }]
 * @param {boolean} loading - while initial options loading, or new option being created
 * @param {boolean} disabled
 * @param {boolean} error
 * @param {boolean} clearable
 * @param {boolean} searchable
 * @param {boolean} creatable
 */
const Select = forwardRef(
  (
    {
      className,
      label,
      id,
      loading,
      disabled,
      error,
      clearable,
      searchable,
      creatable,
      options,
      styles = {},
      ...rest
    },
    ref
  ) => {
    const Component = creatable ? CreatableSelect : SelectComponent;

    const customStyles = useMemo(
      () => ({
        dropdownIndicator: (prev) => ({ ...prev, padding: "7px 8px" }),
        clearIndicator: (prev) => ({ ...prev, padding: "7px 8px" }),
        control: (prev) => ({
          ...prev,
          minHeight: 36,
          backgroundColor: error ? "rgba(255,0,0,0.15)" : "initial",
          ...styles.select,
        }),
        singleValue: (prev) => ({ ...prev, ...styles.valueText }),
      }),
      [styles, error]
    );

    return (
      <div className={cx("mt-4", className)} style={styles.root}>
        {label && (
          <label
            className={cx("text-xs leading-7", !!error && "text-red-600")}
            htmlFor={id || label}
            style={styles.label}
          >
            {label}
          </label>
        )}
        <Component
          id={id || label}
          instanceId={id || label}
          classNamePrefix="select"
          defaultValue={options?.[0]}
          isDisabled={disabled}
          placeholder={disabled ? "" : "Select..."}
          isLoading={loading && !disabled}
          isClearable={clearable}
          isSearchable={searchable}
          createOptionPosition="first"
          // isValidNewOption
          // getNewOptionData
          options={options}
          {...rest}
          ref={ref}
          styles={customStyles}
        />
      </div>
    );
  }
);

Select.displayName = "Select";

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  creatable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  }),
  styles: PropTypes.shape({
    root: PropTypes.object,
    label: PropTypes.object,
    select: PropTypes.object,
    valueText: PropTypes.object,
  }),
};

export default Select;
