import PropTypes from "prop-types";
import cx from "classnames";

const spanStyles = { marginLeft: "0.75ch" };

const Checkbox = ({
  className,
  style,
  label,
  labelClass,
  inputClass,
  disabled,
  marginTop = true,
  ...rest
}) => (
  <div className={className} style={style}>
    <label
      className={cx(
        labelClass,
        "inline-flex items-center text-xs cursor-pointer",
        marginTop && "mt-4"
      )}
    >
      <input
        type="checkbox"
        disabled={disabled}
        className={cx("form-checkbox", inputClass, {
          "bg-header-light": disabled,
        })}
        {...rest}
      />
      <span style={spanStyles} className={cx("opacity-50" && disabled)}>
        {label}
      </span>
    </label>
  </div>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
  marginTop: PropTypes.bool,
};

export default Checkbox;
