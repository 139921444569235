import {
  FaTable,
  FaTruck,
  FaCartPlus,
  FaUsers,
  FaMoneyBill,
  // FaInbox,
} from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import {
  FiRefreshCw,
  //  FiClock
} from "react-icons/fi";
import { /* isProd as prod, */ wmaxxUrl } from "utils/config";

/**
 * Items without "path" go to the old wmaxx/php app (using cmd/params), those with "path" stay in this app
 *
 * Menu highlighting: Items with "params" are matched based on path + params (exact match);
 *  "matcher" function can be defined for other cases
 *
 * Permissions are set by adding an array of roles to the "allow" field.
 *   - Routes are guarded on pathname level (without params)
 *   - Dynamic routes can also be defined, e.g. "shipment-jobs/[shipmentJobId]""
 *   - The Admin role can always access all routes.
 *   - The wmaxx app does NOT currently have guarded routes
 */

export const menu = [
  {
    name: "Dashboard",
    cmd: "dashboard",
    params: "&command=errorlog",
    allow: ["Dashboard"],
    submenu: [
      { name: "Allocation Error", params: "&command=allocationError" },
      { name: "Order Report", params: "&command=orderreport" },
      { name: "Missing UPC", params: "&command=missingUpc" },
      { name: "Amazon Errors", params: "&command=amazonError" },
      { name: "Add to Amazon", params: "&command=addToAmazon" },
      { name: "Fba Qty Errors", params: "&command=fbaQty" },
      {
        name: "Amazon Fee Discount",
        params: "&command=amazonFeeDiscountCheckPrice",
      },
      { name: "Ebay Errors", params: "&command=ebayerror" },
      {
        name: "Ebay Amazon Auto",
        params: "&command=ebayAmazonAutoPublishCompete",
      },
      { name: "Add To Wmaxx", params: "&command=addCodeToWatchmaxx" },
      { name: "Wmaxx Pricing", params: "&command=wmaxxPricingInStockItems" },
      { name: "Cost Low High", params: "&command=costLowHighDoesntMatchBrand" },
      { name: "Missing Image", params: "&command=missingImage" },
    ],
  },
  {
    name: "Orders",
    // cmd: "orders",
    path: "orders",
    // params: "?status_id=all_open_orders_list",
    // orders submenu loaded from api
    allow: ["Orders", "Orders_Full"],
  },
  {
    name: "Catalog",
    cmd: "catalog",
    params: "&command=uploadfile_form",
    allow: ["Catalog", "Catalog_Full"],
    submenu: [
      { name: "New Products Upload", params: "&command=uploadfile_form" },
      // { name: "Products Update", params: "&command=upload_update_file_form" },
      { name: "Images Upload", params: "&command=imageupload" },
      { name: "Daily Deals", params: "&command=dailydeals" },
      { name: "Add Series", params: "&command=add_series" },
      { name: "Modify Specs", params: "&command=modify_specs" },
      { name: "Item Management", params: "&command=item_management" },
      { name: "Brand Management", path: "catalog/brands", allow: ["Catalog", "Catalog_Full"] },
    ],
  },
  {
    name: "Watchswitch",
    cmd: "watchswitch",
    allow: ["Watchswitch"],
  },
  {
    name: "Return / Exchange",
    path: "returns",
    allow: ["Returns", "Returns_Full"],
    params: "?type=0",
    matcher: (p) =>
      p === "/returns" ||
      p.startsWith("/returns?type=0") ||
      p.startsWith("/returns?type=&"),
    submenu: [
      {
        name: "Returns",
        path: "returns",
        params: "?type=1",
        matcher: (p) => p.startsWith("/returns?type=1"),
      },
      {
        name: "Exchanges",
        path: "returns",
        params: "?type=2",
        matcher: (p) => p.startsWith("/returns?type=2"),
      },
      {
        name: "Repairs",
        path: "returns",
        params: "?type=3&progressCategory=5",
        matcher: (p) => p.startsWith("/returns?type=3"),
      },
    ],
  },
  {
    name: "Shipments",
    // cmd: "shipments",
    // params: "&status=1",
    path: "shipments",
    allow: ["Shipments", "Shipments_Full"],
    matcher: (p) => /^\/shipments(?!\/)/.test(p),
    submenu: [
      {
        name: "Packing Slips",
        path: "shipments/packing-slips",
        allow: ["Shipments_Full"],
      },
      {
        name: "Shipment Jobs",
        path: "shipment-jobs",
        allow: ["Shipments_Full"],
      },
    ],
  },
  {
    name: "Inventory",
    cmd: "inventory",
    params: "&command=inventory_overview",
    allow: ["Inventory", "Inventory_Full"],
    submenu: [
      { name: "Availability", params: "&command=availability", add: "" },
      { name: "Suppliers", params: "&command=getsuppliers" },
      {
        name: "Location Overview",
        path: "inventory/locations",
        allow: ["Inventory_Full"],
      },
      {
        name: "Item Locations",
        params: "&command=inventory_overview_locations",
      },
      {
        name: "Stock Check",
        path: "inventory/stock-check",
      },
      { name: "Location Jobs", params: "&command=inventory_location_jobs" },
      { name: "Add Item", params: "&command=inventory_add_item" },
    ],
  },
  {
    name: "Purchasing",
    cmd: "purchasing",
    params: "&command=purchase_orders",
    allow: ["Purchasing", "Purchasing_Full"],
    submenu: [
      { name: "Purchase Orders", params: "&command=purchase_orders" },
      { name: "Bills", params: "&command=bills" },
      { name: "Bill Issues", params: "&command=bill_issues" },
      { name: "Receiving", params: "&command=receiving" },
    ],
  },
  {
    name: "Suppliers",
    cmd: "suppliers",
    params: "&command=getSuppliers",
    allow: ["Catalog", "Catalog_Full"],
    submenu: [
      { name: "Dropship", params: "&command=supplier_dropship" },
      { name: "Dropship Upload", params: "&command=supplier_upload_dropship" },
      { name: "Supplier Codes", params: "&command=supplier_codes" },
      { name: "Upload Supplier Codes", params: "&command=upload_codes" },
    ],
  },
  {
    name: "Coupons",
    // path: prod ? "" : "coupons",
    // cmd: prod ? "coupons" : undefined,
    // params: prod ? "&command=show_coupons&type=regular_coupons" : "",
    path: "coupons",
    allow: ["Coupons", "Coupons_Full"],
    submenu: [
      { name: "Quick Deals", path: "coupons", params: "?quick_deal=1" },
    ],
  },
  {
    name: "Admin",
    path: "admin",
    allow: ["Admin"],
    submenu: [
      {
        name: "Users",
        path: "users",
        allow: ["Admin"],
      },
      {
        name: "Portal Users",
        path: "portal-users",
        allow: ["Admin"],
      },
      {
        name: "Cron Jobs",
        path: "cronjobs",
        allow: ["Admin"],
      },
      // {
      //   name: "News",
      //   cmd: "content",
      //   params: "&command=newslist",
      // },
    ],
  },

  {
    name: "Trade Ins",
    cmd: "tradein",
    params: "&command=list",
    allow: ["TradeIn", "TradeIn_Full"],
  },
];

export const menuIcons = {
  Dashboard: <AiFillDashboard />,
  Orders: <FaTable />,
  Inventory: <FaTruck />,
  Purchasing: <FaCartPlus />,
  Suppliers: <FaUsers />,
  Coupons: <FaMoneyBill />,
  // CronJobs: <FiClock />,
  // News: <FaInbox />,
  "Trade Ins": <FiRefreshCw />,
};

export const parentPathMapping = menu.reduce((mapping, item) => {
  if (item.submenu) {
    item.submenu.forEach((submenuItem) => {
      if (submenuItem.path) mapping[submenuItem.path] = item.cmd || item.path;
    });
  }
  return mapping;
}, {});

const nonMenuPages = [
  {
    name: "Login",
    path: "login",
  },
  {
    name: "Unauthorized",
    path: "unauthorized",
    hideLayout: true,
  },
  {
    name: "Front Plugin",
    path: "front-plugin",
    hideLayout: true,
    allow: ["Front_Plugin", "Wmaxx"],
  },
  {
    name: "Inventory Logging",
    path: "inventory/logging",
    hideLayout: true,
    allow: ["Inventory", "Inventory_Full"],
  },
  {
    name: "Get Payment Link",
    path: "get-payment-link",
    hideLayout: true,
    allow: ["Front_Plugin", "Wmaxx"],
  },
  {
    // name: "Shipment Job Detail",
    path: "shipment-jobs/[shipmentJobId]",
    allow: ["Shipments", "Shipments_Full"],
  },
  // {
  //   name: "Coupon Detail",
  //   path: "coupons/[couponId]",
  //   allow: ["Coupons", "Coupons_Full"],
  // },
];

export const pageInfo = menu
  .concat(nonMenuPages)
  .flatMap((i) => [i, ...(i.submenu || [])])
  .reduce(
    (obj, i) => {
      if (!i.path) return obj;
      const pathWithParams = i.path + (i.params || "");
      if (pathWithParams in obj.titles)
        throw new Error(`Duplicate path: ${pathWithParams}`);
      return {
        titles: { ...obj.titles, [pathWithParams]: i.name },
        allow: { ...obj.allow, [i.path]: i.allow },
        hideLayout: i.hideLayout
          ? { ...obj.hideLayout, [i.path]: true }
          : obj.hideLayout,
      };
    },
    { titles: {}, hideLayout: {}, allow: {} }
  );

export const oldBackEndUrl = (currentUrl) => {
  const searchParams = new URLSearchParams(currentUrl.split("?")[1]);
  const map = [
    { curr: "/shipments", old: "?cmd=shipments&status=1" },
    {
      curr: "/coupons?quick_deal=1",
      old: "?cmd=coupons&command=show_coupons&type=quick_deal",
    },
    {
      curr: "/coupons",
      old: "?cmd=coupons&command=show_coupons&type=regular_coupons",
    },
    {
      curr: "/orders",
      old: "?cmd=orders&status_id=" + (searchParams.get("status_id") || ""),
    },
    // {
    //    curr: "/returns",
    //    old: "?cmd=returns_list&status=" + (searchParams.get("status") || ""),
    // },
  ];
  return (
    wmaxxUrl + (map.find((el) => currentUrl.startsWith(el.curr))?.old || "")
  );
};
