import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BsArrowLeft } from "react-icons/bs";

const BackArrow = ({ forReferrer }) => {
  const [showBackArrow, setShowBackArrow] = useState();
  useEffect(() => {
    if (document.referrer === forReferrer || !forReferrer)
      setShowBackArrow(true);
  }, [forReferrer]);

  return (
    showBackArrow && (
      <BsArrowLeft
        className="fixed top-0 left-0 m-4 cursor-pointer text-base"
        onClick={() => window.history.back()}
      />
    )
  );
};

BackArrow.propTypes = { forReferrer: PropTypes.string };

export default BackArrow;
