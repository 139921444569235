import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ReactDatePicker from "react-datepicker";

const DatePicker = ({ label, className, classes = {}, ...rest }) => (
  <div className={cx("mt-4", className)}>
    <div className="leading-7">{label}</div>
    <ReactDatePicker
      className={cx("h-8 border rounded", classes.input)}
      {...rest}
    />
  </div>
);

DatePicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
};

export default DatePicker;
