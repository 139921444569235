import { forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const TextField = forwardRef(
  (
    {
      className,
      classes = {},
      style,
      styles = {},
      label,
      disabled,
      error,
      symbol,
      ...rest
    },
    ref
  ) => (
    <label
      className={cx("inline-block w-full mt-4 text-xs", className)}
      style={styles.label}
    >
      <span className={cx("leading-7", !!error && "text-red-600")}>
        {label}
      </span>
      <div className="relative">
        <input
          disabled={disabled}
          className={cx(
            "form-input w-full h-9 text-sm border-input-border",
            classes.input,
            {
              "pl-8": symbol === "dollars",
              "pr-8": symbol === "percent",
              "bg-light": disabled,
              "bg-red-200": error,
            }
          )}
          {...rest}
          style={styles.input || style}
          ref={ref}
        />
        {symbol === "dollars" && (
          <div className="absolute top-0 left-0 h-full w-6 bg-mediumShading flex items-center justify-center font-bold text-submenuTitle border-r">
            $
          </div>
        )}
        {symbol === "percent" && (
          <div className="absolute top-0 right-0 h-full w-6 bg-mediumShading flex items-center justify-center font-bold text-submenuTitle border-l">
            %
          </div>
        )}
      </div>
    </label>
  )
);

TextField.displayName = "TextField";

TextField.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  style: PropTypes.object,
  styles: PropTypes.shape({ label: PropTypes.object, input: PropTypes.object }),
  label: PropTypes.string,
  symbol: PropTypes.oneOf(["percent", "dollars"]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default TextField;
