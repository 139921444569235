import { gql } from "@apollo/client";

export const getQueryName = (query) => query?.definitions[0].name.value;

export const getQueryRootField = (query) =>
  query?.definitions[0].selectionSet.selections[0].name.value;

/*** Auth ***/

export const GET_CMS_USER_ID = gql`
  query getCmsUserId {
    getCmsUserId
  }
`;

export const IS_CMS_USER_ACTIVE = gql`
  query IsCmsUserActive {
    isCmsUserActive {
      isActive
      timeSinceActive
    }
  }
`;

export const LOGOUT_WMAXX = gql`
  mutation Logout {
    logout
  }
`;

/*** Orders ***/

export const ORDERS_SUBMENU = gql`
  query OrdersSubmenu {
    sections: ordersSubmenu {
      section_title: title
      items {
        id
        name
        count
      }
    }
  }
`;

export const ORDERS_BY_STATUS = gql`
  query OrdersByStatus(
    $status: ID!
    $limit: Int!
    $offset: Int!
    $withExtraDetails: Boolean! = false
    $withSuppliers: Boolean! = false
    $withInventory: Boolean! = false
    $filtersAndSorting: [FilteringAndSorting!]!
    $globalFilter: [FilteringAndSorting!]!
  ) {
    ordersByStatus(
      status: $status
      limit: $limit
      offset: $offset
      filteringAndSorting: $filtersAndSorting
      globalFilter: $globalFilter
    ) {
      status: statusInfo {
        id
        name
        count
      }
      resultCount
      orders {
        id
        time_stamp
        is_shopper @include(if: $withExtraDetails)
        chargeback @include(if: $withExtraDetails) {
          typeName
        }
        weight @include(if: $withExtraDetails)
        status: statusInfo {
          # id
          name
        }
        shippingAddress {
          name
          first_name
          last_name
          country
          # phone
        }
        customerInfo {
          email
          verified @include(if: $withExtraDetails)
        }
        method: payType {
          name
        }
        source: source_name
        details {
          code
          price
          in_stock: status @include(if: $withInventory)
          item: itemInfo {
            brand
            # sale_price
            retail_price
            # savings
            # save_percent
            # in_stock @include(if: $withInventory)
            watch_switch @include(if: $withInventory)
            dropship @include(if: $withInventory)
            inventory: inventoryOverview @include(if: $withInventory) {
              onPo
              onBill
              availableAllocate
            }
          }
          qty
        }
        total
        shippingInfo {
          name
          abbr
        }
        ship_name
        minShipDay
        #suppliers @include(if: $withSuppliers) {
        #  name
        #  nickname
        #}
        purchaseOrders @include(if: $withSuppliers) {
          # id
          supplier {
            name
            #nickname
          }
        }
        bulkShipmentJobOrders @include(if: $withExtraDetails) {
          id
        }
      }
    }
  }
`;

export const CHANGE_ORDER_STATUS = gql`
  mutation changeOrderStatus(
    $orderIds: [Int!]!
    $status: Int!
    $comment: String
    $markAsVerified: Boolean
  ) {
    changeOrderStatus(
      orderIds: $orderIds
      status: $status
      comment: $comment
      markAsVerified: $markAsVerified
    ) {
      id
      #status_id
      #comment
      #userId
      orders: changeOrdersStatusOrderIds {
        orderId
        errorMsg
      }
    }
  }
`;

/*** Shipments ***/

const ShipmentFragment = gql`
  fragment ShipmentInfo on Shipment {
    id
    orderId
    timeCreated @include(if: $withSpecialDetails)
    timeUpdated
    isPrepared
    issue @include(if: $withSpecialDetails)
    serviceCode
    orderInfo {
      # ship_name
      source_name
      time_stamp
      needs_sizing @include(if: $withSpecialDetails)
      # items {
      #   inventoryOverview {
      #     onHand
      #   }
      # }
      shippingInfo {
        name
      }
    }
    # trackingNumber
    shippingServiceInfo {
      name
      # url
    }
    shipmentItemsWithCount {
      code
      count
    }
    shipmentDetailsList @include(if: $withSpecialDetails) {
      orderDetailInfo {
        watchSwitchInfo {
          statusId
        }
        itemInfo {
          inventoryOverview {
            # code
            onHand
          }
        }
      }
    }
    shipmentBrands {
      brand
    }
  }
`;

export const SHIPMENTS_BY_STATUS = gql`
  query shipmentsByStatus(
    $status: ID!
    $limit: Int!
    $offset: Int!
    $date: Date
    $withSpecialDetails: Boolean! = false
    $filtersAndSorting: [FilteringAndSorting!]!
    $globalFilter: [FilteringAndSorting!]!
  ) {
    shipmentsByStatus(
      status: $status
      limit: $limit
      offset: $offset
      date: $date
      filteringAndSorting: $filtersAndSorting
      globalFilter: $globalFilter
    ) {
      count
      resultCount
      shipmentsList {
        ...ShipmentInfo
      }
      locationInfo: shipmentsLocationsWithShipmentsLocationsCount {
        shipments: shipmentsLocations {
          id
          locations: locationsList
        }
        locations: shipmentsLocationsCount {
          name: locationName
          count
        }
      }
    }
  }
  ${ShipmentFragment}
`;

export const SHIPMENTS_STATUS_COUNTS = gql`
  query ShipmentsStatusCounts($date: Date) {
    shipmentsStatusCounts(date: $date) {
      count
      shipmentStatus {
        id
        name
      }
    }
  }
`;

export const SHIPMENT_BY_ID = gql`
  query ShipmentById($id: Int!) {
    shipmentById(id: $id) {
      orderInfo {
        status
      }
    }
  }
`;

export const SHIPMENTS_TO_PULL = gql`
  query ShipmentsToPull {
    shipmentsToPull {
      shipmentId
      orderId
      status
      reship
      updatedAt
      cmsUserInfo {
        name
      }
    }
  }
`;

export const UPDATE_SHIPMENT_PULL_STATUS = gql`
  mutation UpdateShipmentPullStatus($shipmentId: Int!, $status: Int!) {
    updateShipmentPullStatus(shipmentId: $shipmentId, status: $status)
  }
`;

// export const CREATE_SHIPMENT_PACKING_SLIPS = gql`
//   mutation createShipmentPackingSlips($shipmentIds: [Int!]!) {
//     createShipmentPackingSlips(shipmentIds: $shipmentIds) {
//       # id
//       filePath
//       # userId
//     }
//   }
// `;

export const CREATE_SHIPMENT_PACKING_SLIPS = gql`
  mutation createShipmentPackingSlipsWithLocations(
    $shipmentsWithLocations: [ShipmentsLocationsInputType!]!
    $labelSize: String!
    $orderBy: String!
    $ignoreErrors: Boolean!
    $markAsPrepared: Boolean!
  ) {
    createShipmentPackingSlipsWithLocations(
      shipmentsLocations: $shipmentsWithLocations
      labelSize: $labelSize
      groupBy: $orderBy
      ignoreErrors: $ignoreErrors
      markAsPrepared: $markAsPrepared
    ) {
      id
      filePath
      # userId
    }
  }
`;

export const NO_AVAILABLE_LOCATION_ERROR =
  "ShipmentHasNoAvailableLocationException";

export const SHIPMENTS_PACKING_SLIPS = gql`
  query shipmentBulkPackingSlip($limit: Int!, $offset: Int!, $date: Date) {
    shipmentBulkPackingSlip(limit: $limit, offset: $offset, date: $date) {
      count
      shipmentPackingSlips {
        id
        #userId
        #updatedAt
        filePath
        createdAt
        shipmentsList {
          #id
          orderId
          statusId
          shipmentBrands {
            brand
          }
        }
      }
    }
  }
`;

/*** Shipment Jobs ***/

export const SHIPMENT_JOB_OPTIONS = gql`
  query ShipmentJobOptions {
    labelOptions {
      value: id
      label: name
      localPickup: isLocalPickup
    }
    shippingOptions {
      value: id
      label: desc
      provider
      carrier: name
      #serviceCode
    }
    signatureOptions: shippingSignatureOptions {
      value: id
      label: description
      provider
      carrier
      #code
    }
  }
`;

export const SHIPMENT_JOB_CREATE = gql`
  mutation ShipmentJobCreate(
    $orderIds: [Int!]!
    $labelOption: Int!
    $shippingOption: Int
    $signatureOption: Int
    $isFedexPak: Boolean!
    $insurance: Boolean!
    $autoBarcode: Boolean!
    $isDropship: Boolean!
    $lbs: Int!
    $oz: Int!
    $userId: Int
    $useOrderWeight: Boolean
  ) {
    createShipmentJob(
      bulkJob: {
        orderIds: $orderIds
        labelOption: $labelOption
        shippingOption: $shippingOption
        shippingSignature: $signatureOption
        isFedexPak: $isFedexPak
        withInsurance: $insurance
        autoBarcode: $autoBarcode
        isDropship: $isDropship
        lbs: $lbs
        oz: $oz
        userId: $userId
        useOrderWeight: $useOrderWeight
      }
    ) {
      jobId: id
      orders {
        #totalCount: countAll
        #errorCount: countWithErrors
        errorList: listWithErrors {
          orderId
          errorMsg
        }
      }
    }
  }
`;

export const SHIPMENT_JOB_CREATE_AUTO = gql`
  mutation ShipmentJobCreateAuto(
    $labelOption: Int!
    $autoBarcode: Boolean!
    $isDropship: Boolean!
    $orderIds: [Int!]!
    $userId: Int
  ) {
    createAutoShipmentJob(
      bulkAutoJob: {
        labelOption: $labelOption
        autoBarcode: $autoBarcode
        isDropship: $isDropship
        orderIds: $orderIds
        userId: $userId
      }
    ) {
      jobId: id
      orders {
        errorList: listWithErrors {
          orderId
          errorMsg
        }
      }
    }
  }
`;

const ShipmentJobFragment = gql`
  fragment ShipmentJobInfo on BulkShippingJob {
    jobId: id
    status {
      id
      name
    }
    cmsUserInfo {
      userName
      #name
    }
    autoBarcode: fillBarcode
    isDropship: isDropShip
    labelInfo: labelPack {
      labelOption: name
      localPickup: isLocalPickup
    }
    labelsLink: labelPath
    packingSlipsLink: packingSlipPath
    orders {
      totalCount: countAll
      list {
        orderId
        isFedexPak
        insurance: withInsurance
        weight @include(if: $full)
        # rate @include(if: $full)
        shippingServiceType {
          desc
          provider
        }
        shippingSignature @include(if: $full) {
          description
        }
        errorMsg
      }
      errorCount: countWithErrors
      #errorList: listWithErrors {
      #  orderId
      #  errorMsg
      #}
    }
    labels {
      startedAt
      #countAll
      #countWithErrors
      list {
        orderId
        charges @include(if: $full)
        errorMsg
      }
    }
    isAuto
    # updatedAt
    completedAt
  }
`;

export const SHIPMENT_JOB_START = gql`
  mutation StartShipmentJob($jobId: Int!, $full: Boolean! = true) {
    startShipmentJob(jobId: $jobId) {
      ...ShipmentJobInfo
    }
  }
  ${ShipmentJobFragment}
`;

export const SHIPMENT_JOB_CANCEL = gql`
  mutation CancelShipmentJob($jobId: Int!) {
    cancelShipmentJob(jobId: $jobId) {
      jobId: id
    }
  }
`;

export const SHIPMENT_JOBS_LIST = gql`
  query ShipmentJobsList($limit: Int!, $offset: Int!, $full: Boolean! = false) {
    bulkShipmentJobList(limit: $limit, offset: $offset) {
      count
      jobs {
        ...ShipmentJobInfo
      }
    }
  }
  ${ShipmentJobFragment}
`;

export const SHIPMENT_JOB_BY_ID = gql`
  query ShipmentJobById($jobId: Int!, $full: Boolean! = true) {
    bulkShipmentJobById(id: $jobId) {
      ...ShipmentJobInfo
    }
  }
  ${ShipmentJobFragment}
`;

/*** COUPONS ***/

/* export const COUPON_LIST = gql`
  query CouponList() {
    coupons {
      id
      code
      discount_type: discountType
      amount
      percentage
      date_begins: dateBegins
      date_expires: dateExpires
      usage_count: usageCount
      order_count: orderCount
      product_ids: productIds
      brand_ids: brandIds
      type_ids: typeIds
      minimumAmount
      show_on_site: showOnSite
      send_to_google: sendToGoogle
    }
  }
`; */

export const COUPONS_WITH_COUNT = gql`
  query CouponsWithCount(
    $quickDeal: Boolean!
    $limit: Int!
    $offset: Int!
    # $globalFilter: String
    $filtersAndSorting: [FilteringAndSorting!]!
  ) {
    couponsWithCount(
      quickDeal: $quickDeal
      limit: $limit
      offset: $offset
      # globalFilter: $globalFilter
      filteringAndSorting: $filtersAndSorting
    ) {
      list {
        id
        code
        discount_type: discountType
        amount
        percentage
        date_begins: dateBegins
        date_expires: dateExpires
        #individualUse
        #usageLimit
        usage_count: usageCount
        order_count: orderCount
        product_ids: productIdList
        brand_ids: brandIdList
        type_ids: typeIdList
        minimumAmount
        show_on_site: showOnSite
        send_to_google: sendToGoogle
        # productIdList
      }
      count
      resultCount
    }
  }
`;

export const BULK_UPDATE_COUPONS = gql`
  mutation BulkUpdateCoupons(
    $ids: [Int!]!
    $dateBegins: DateTime
    $dateExpires: DateTime
    $amount: Float
    $percentage: Boolean
    $showOnSite: Boolean
  ) {
    updateCoupons(
      couponBulkUpdate: {
        ids: $ids
        dateBegins: $dateBegins
        dateExpires: $dateExpires
        amount: $amount
        percentage: $percentage
        showOnSite: $showOnSite
      }
    )
  }
`;

/*** RETURNS / REPAIRS ***/

export const RETURNS_BY_TYPE_AND_STATUS = gql`
  query ReturnsByTypeAndStatus(
    $type: Int
    $status: Int!
    $date: Date
    $limit: Int!
    $offset: Int!
    $withRepairProgress: Boolean!
    $withInventoryIds: Boolean!
  ) {
    returns(
      type: $type
      status: $status
      date: $date
      limit: $limit
      offset: $offset
    ) {
      count
      returnsList {
        id
        orderId
        # cmsUserId
        statusId
        #reasonId
        #customerNotes
        officeNotes
        #exchangeFor
        #declineExplanation
        #declineReasonId
        timeCreated
        lastModified
        returnDetails {
          orderDetail {
            code
            shipmentDetailInfo @include(if: $withInventoryIds) {
              inventoryId
            }
          }
          returnType {
            name
          }
          latestRepairProgress @include(if: $withRepairProgress) {
            # statusId
            timestamp
            repairProgressName {
              internalName
            }
          }
        }
        status {
          name
          id
        }
        #reason {
        #  id
        #}
        orderInfo {
          id
          source_name
          # items {
          #  code
          # }
          statusInfo {
            name
          }
        }
        # cmsUserInfo {
        # id
        # userName
        # name
        # }
      }
    }
  }
`;

export const RETURNS_STATUS_COUNTS = gql`
  query ReturnsStatusCounts($type: Int, $date: Date) {
    returnsStatusCounts(type: $type, date: $date) {
      count
      returnsStatus {
        id
        name
      }
    }
  }
`;

export const REPAIRS_BY_PROGRESS_CATEGORY = gql`
  query repairsByProgressCategory(
    $progressCategory: Int!
    $date: Date
    $limit: Int!
    $offset: Int!
    $withInventoryIds: Boolean!
  ) {
    repairsByProgressCategory(
      progressCategory: $progressCategory
      date: $date
      limit: $limit
      offset: $offset
    ) {
      count
      returnsList {
        id
        orderId
        statusId
        officeNotes
        timeCreated
        lastModified
        returnDetails {
          orderDetail {
            code
            shipmentDetailInfo @include(if: $withInventoryIds) {
              inventoryId
            }
          }
          returnType {
            name
          }
          latestRepairProgress {
            # statusId
            timestamp
            repairProgressName {
              internalName
            }
          }
        }
        status {
          name
          id
        }
        orderInfo {
          id
          source_name
          statusInfo {
            name
          }
        }
      }
    }
  }
`;

export const REPAIR_PROGRESS_CATEGORY_COUNTS = gql`
  query RepairProgressCategoryCounts($date: Date) {
    repairProgressCategoryCounts(date: $date) {
      count
      repairProgressCategory {
        id
        name
      }
    }
  }
`;

/*** CRON JOBS / TASKS ***/

const CronJobFragment = gql`
  fragment CronJob on Job {
    id
    name
    schedule
    description
    isActive
    createdAt
    updatedAt
  }
`;

const CronTaskFragment = gql`
  fragment CronTask on Task {
    id
    jobId
    orderNum
    name
    typeId
    command
    runLimit
    emailFrom
    emailSubject
    emailFormat
    createdAt
    updatedAt
    cmsUserInfo {
      name
    }
    emailBcc {
      emailAddress {
        id
        email
        isActive
      }
    }
  }
`;

export const CRON_JOBS = gql`
  query CronJobs($limit: Int!, $offset: Int!) {
    cronJobs(limit: $limit, offset: $offset) {
      count
      jobList {
        ...CronJob
        tasks {
          id
          name
          orderNum
        }
        cmsUserInfo {
          name
        }
      }
    }
  }
  ${CronJobFragment}
`;

export const CREATE_CRON_JOB = gql`
  mutation CreateCronJob(
    # $id: Int
    $name: String!
    $schedule: String!
    $description: String!
    $isActive: Boolean!
  ) {
    createCronJob(
      job: {
        name: $name
        schedule: $schedule
        description: $description
        isActive: $isActive
      }
    ) {
      ...CronJob
    }
  }
  ${CronJobFragment}
`;

export const UPDATE_CRON_JOB = gql`
  mutation UpdateCronJob(
    $id: Int!
    $name: String!
    $schedule: String!
    $description: String!
    $isActive: Boolean!
  ) {
    updateCronJob(
      job: {
        id: $id
        name: $name
        schedule: $schedule
        description: $description
        isActive: $isActive
      }
    )
  }
`;

export const DELETE_CRON_JOB = gql`
  mutation DeleteCronJob($jobId: Int!) {
    deleteCronJob(jobId: $jobId)
  }
`;

export const CRON_TASKS = gql`
  query CronTasks($jobId: Int!, $limit: Int!, $offset: Int!) {
    cronTasks(jobId: $jobId, limit: $limit, offset: $offset) {
      count
      taskList {
        ...CronTask
      }
      taskTypes {
        id
        name
      }
      emailAddresses {
        id
        email
        isActive
        isSender
      }
      emailFormat {
        id
        title
      }
    }
  }
  ${CronTaskFragment}
`;

export const CREATE_CRON_TASK = gql`
  mutation CreateCronTask(
    # $id: Int!
    $jobId: Int!
    $orderNum: Int!
    $name: String!
    $typeId: Int!
    $command: String!
    $runLimit: Int!
    $email: EmailInfoInput
  ) {
    createCronTask(
      task: {
        jobId: $jobId
        orderNum: $orderNum
        name: $name
        typeId: $typeId
        command: $command
        runLimit: $runLimit
        email: $email
      }
    ) {
      ...CronTask
    }
  }
  ${CronTaskFragment}
`;

export const UPDATE_CRON_TASK = gql`
  mutation UpdateCronTask(
    $id: Int!
    $jobId: Int!
    $orderNum: Int!
    $name: String!
    $typeId: Int!
    $command: String!
    $runLimit: Int!
    $email: EmailInfoInput
  ) {
    updateCronTask(
      task: {
        id: $id
        jobId: $jobId
        orderNum: $orderNum
        name: $name
        typeId: $typeId
        command: $command
        runLimit: $runLimit
        email: $email
      }
    )
  }
`;

export const DELETE_CRON_TASK = gql`
  mutation DeleteCronTask($taskId: Int!) {
    deleteCronTask(taskId: $taskId)
  }
`;

export const RUN_DEMO_TASK = gql`
  mutation runDemoTask($taskInput: TaskInput!) {
    runDemoTask(task: $taskInput) {
      emailContent {
        subject
        message
        # isHtml
      }
      exception
    }
  }
`;

/*** Front Plugin ***/

const AddressFragment = gql`
  fragment AddressInfo on Address {
    titleHonorific
    first_name
    last_name
    name
    address1
    address2
    city
    state
    zip
    country
    phone
  }
`;

const OrderFragment = gql`
  fragment OrderInfo on Order {
    id
    time_stamp
    customer_note
    status: statusInfo {
      #id
      name
    }
    shippingAddress {
      ...AddressInfo
    }
    customerInfo {
      email
    }
    method: payType {
      name
    }
    source: source_name
    items {
      brand
      series
      code
      in_stock
      item_url
    }
    details {
      warranty_id
      code
      status
      #     item: itemInfo {
      #       brand
      #     }
      #     qty
      # shipmentDetailInfo {
      #   shipmentInfo {
      #     shippingStatusInfo {
      #       id # 5 = delivered
      #       name
      #     }
      #   }
      # }
    }
    total
    refund
    shippingInfo {
      name
      # abbr
    }
    shipments {
      trackingNumber
      isDropShip
      shippingServiceInfo {
        # name
        url
      }
    }
    # ship_name
    minShipDay
    maxShipDay
    minDeliveryDay
    maxDeliveryDay
  }
  ${AddressFragment}
`;

export const USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      profile {
        ...AddressInfo
      }
      orders {
        ...OrderInfo
      }
    }
  }
  ${AddressFragment}
  ${OrderFragment}
`;

export const USERS_BY_PHONE = gql`
  query getUsersByPhone($phone: String!) {
    getUsersByPhone(phone: $phone) {
      id
      email
      profile {
        ...AddressInfo
      }
      orders {
        ...OrderInfo
      }
    }
  }
  ${AddressFragment}
  ${OrderFragment}
`;

export const USER_BY_EBAY_ORDER_ID = gql`
  query getUserByEbayOrderNumber($ebayOrderNumber: String!) {
    getUserByEbayOrderNumber(ebayOrderNumber: $ebayOrderNumber) {
      email
      profile {
        ...AddressInfo
      }
      orders {
        ...OrderInfo
        ebay_order_number
      }
    }
  }
  ${AddressFragment}
  ${OrderFragment}
`;

export const USER_BY_AMAZON_ORDER_ID = gql`
  query getUserByAmazonOrderNumber($amazonOrderNumber: String!) {
    getUserByAmazonOrderNumber(amazonOrderNumber: $amazonOrderNumber) {
      email
      profile {
        ...AddressInfo
      }
      orders {
        ...OrderInfo
        amazon_store_order_id
      }
    }
  }
  ${AddressFragment}
  ${OrderFragment}
`;

export const ORDER_BY_ID = gql`
  query OrderById($id: Int!) {
    orderById(id: $id) {
      ...OrderInfo
      customerInfo {
        id
        profile {
          ...AddressInfo
        }
      }
    }
  }
  ${OrderFragment}
  ${AddressFragment}
`;

/*** Catalog / Inventory ***/

export const INVENTORY_BY_ID = gql`
  query InventoryById($id: Int!, $withShipmentDetails: Boolean! = false) {
    inventoryById(id: $id) {
      code
      location
      inStock
      statusId
      shipmentDetailsList @include(if: $withShipmentDetails) {
        orderDetailInfo {
          id
          order_id
          returns_id
        }
      }
    }
  }
`;

export const GET_BRANDS_TYPES = gql`
  query getBrandsTypes($limit: Int!, $offset: Int, $full: Boolean = false) {
    getBrandsTypes(limit: $limit, offset: $offset) {
      id
      brand: name
      type: itemType
      costPercentLow @include(if: $full)
      costPercentHigh @include(if: $full)
      pricingAlert @include(if: $full)
      isActive @include(if: $full)
      requiresUpc @include(if: $full)
      hasSerial @include(if: $full)
      outOfStockStatus @include(if: $full)
      tagBrandPercent @include(if: $full)
      origin @include(if: $full)
      autoApprove @include(if: $full)
      tradeIn @include(if: $full)
      dropship @include(if: $full)
      minHasNeedItSooner @include(if: $full)
    }
  }
`;

export const GET_BRANDS_TYPE = gql`
  query getBrandsType($id: Int!) {
    getBrandsType(id: $id) {
      id
      brand: name
      type: itemType
      costPercentLow
      costPercentHigh
      pricingAlert
      isActive
      requiresUpc
      hasSerial
      outOfStockStatus
      origin
      autoApprove
      tradeIn
      dropship
      minHasNeedItSooner
      needsImageForEbay
      tagBrandPercent
      sellOnAmazon
      quickbooksAccount
      ebayProductCategory
      googleProductCategory
      sellOnEbay
      canReturn
    }
  }
`;

export const BRANDS_TYPES_AUTOCOMPLETE = gql`
  query brandsTypesAutocomplete {
    brandsTypesAutocomplete {
      origins
      ebayProductCategories
      googleProductCategories
      quickbooksAccounts
    }
  }
`;

export const ADD_EDIT_BRANDS_TYPE = gql`
  mutation addEditBrandsType($brandsType: BrandsTypeInputType!) {
    addEditBrandsType(brandsType: $brandsType) {
      name
      itemType
    }
  }
`;

export const UPDATE_BRANDS_TYPES = gql`
  mutation updateBrandsTypes(
    $brandsTypeBulkUpdate: BrandsTypeBulkUpdateInput!
  ) {
    updateBrandsTypes(brandsTypeBulkUpdate: $brandsTypeBulkUpdate)
  }
`;
