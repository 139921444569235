import PropTypes from "prop-types";
import cx from "classnames";
import { useQuery } from "@apollo/client";
import {
  INVENTORY_BY_ID,
  getQueryName,
  getQueryRootField,
} from "utils/gqlQueries";
import { Spinner, ExternalLink } from "components";
import { wmaxxUrl } from "utils/config";
import { getOrderDetailLink } from "utils/utils";

const returnsPath = `${wmaxxUrl}?cmd=returns_detail&returns_id=`;
const inventoryHistoryPath = `${wmaxxUrl}?cmd=inventory&command=inventory_item&inventory_tab=inventory_history&code=`;
const inventoryLogPath = `${wmaxxUrl}?cmd=inventory&command=inventory_item&inventory_tab=inventory_log&code=`;

const BarcodeLinks = ({ barcode, reset, onSelect }) => {
  const id = Number(barcode);

  const { loading, data, error } = useQuery(INVENTORY_BY_ID, {
    displayName: getQueryName(INVENTORY_BY_ID),
    variables: { id, withShipmentDetails: true },
    skip: !id,
  });

  const rootField = getQueryRootField(INVENTORY_BY_ID);
  const code = data?.[rootField]?.code;
  const orderDetails = data?.[rootField]?.shipmentDetailsList.map(
    (sd) => sd.orderDetailInfo
  );

  return (
    <div
      className={cx(
        "absolute top-0 bottom-0 right-0 pt-1 px-4 bg-white transition-side",
        id ? "left-0" : "left-full"
      )}
    >
      <div
        className="inline-block leading-none cursor-pointer"
        onClick={() => reset()}
      >
        ←
      </div>

      <h4 className="m-0 text-lg leading-6">Barcode {id}</h4>
      {loading ? (
        <Spinner />
      ) : error ? (
        <div className="text-red-600">Sorry, there was an error.</div>
      ) : !code ? (
        <div className="opacity-75">Not found.</div>
      ) : (
        <>
          <div>
            <ExternalLink
              className="mt-1"
              href={`${inventoryHistoryPath}${code}#${barcode}`}
              onClick={() => onSelect()}
            >
              Inventory history
            </ExternalLink>
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <ExternalLink
              className="mt-1"
              href={`${inventoryLogPath}${code}#${barcode}`}
              onClick={() => onSelect()}
            >
              Inventory log
            </ExternalLink>
          </div>
          {orderDetails.length < 1 ? (
            <div className="mt-3 opacity-50">
              No orders found for this barcode.
            </div>
          ) : (
            <ul className="mt-1">
              {orderDetails.map((od) => (
                <li key={od.id}>
                  <ExternalLink
                    href={getOrderDetailLink(od.order_id)}
                    onClick={() => onSelect()}
                  >
                    Order # {od.order_id}
                  </ExternalLink>
                  {od.returns_id && (
                    <>
                      {` – `}
                      <ExternalLink
                        href={`${returnsPath}${od.returns_id}`}
                        onClick={() => onSelect()}
                      >
                        Return # {od.returns_id}
                      </ExternalLink>
                    </>
                  )}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

BarcodeLinks.propTypes = {
  barcode: PropTypes.string,
  reset: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default BarcodeLinks;
