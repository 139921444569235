import PropTypes from "prop-types";
import cx from "classnames";

const Button = ({ theme, children, className, ...rest }) => (
  <button
    className={cx(
      "btn",
      {
        "btn-blue": theme === "blue",
        "btn-blue-reverse": theme === "blue-reverse",
        "btn-gray": theme === "gray",
        "btn-gray-light": theme === "gray-light",
        "btn-red": theme === "red",
      },
      className
    )}
    {...rest}
  >
    {children}
  </button>
);

Button.propTypes = {
  theme: PropTypes.oneOf(["blue", "blue-reverse", "gray", "gray-light", "red"]),
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  // style: PropTypes.object,
};

export default Button;
