import React, { memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FiRefreshCw } from "react-icons/fi";
import { normalizeOrderMenuName } from "utils/utils";

const OrdersSubmenu = ({
  sections,
  asPath,
  handleOrdersSubmenuClick,
  handleRefresh,
  refreshing,
  error,
}) => (
  <>
    <div
      className={cx(
        "inline-flex items-center ml-4 text-xxs cursor-pointer",
        error ? "text-red-600" : "text-green-500"
      )}
      onClick={handleRefresh}
    >
      <FiRefreshCw
        className={cx("mr-2", {
          rotating: refreshing || typeof window === "undefined",
        })} // fix!
      />
      <div>{error ? "Error fetching counts" : "Refresh"}</div>
    </div>
    {sections?.map((section) => (
      <li key={section.section_title} className="pl-6 pr-4 whitespace-no-wrap">
        <h5 className="-ml-2 text-submenuTitle">{section.section_title}</h5>
        <ul>
          {section.items.map((menu_item) => (
            <li key={menu_item.id} className="">
              <a
                href={`/orders?status_id=${menu_item.id}`}
                className={cx(
                  "flex justify-between h-8",
                  asPath === `/orders?status_id=${menu_item.id}` // active item
                    ? "font-bold text-blue-500 hover:text-blue-400"
                    : "text-sidebarText hover:text-white"
                )}
                onClick={(e) => handleOrdersSubmenuClick(e, menu_item)}
              >
                <div>{normalizeOrderMenuName(menu_item.name)}</div>
                <div className="ml-2 px-1 h-4 rounded-lg text-xxs leading-normal font-semibold text-master bg-header-light">
                  {menu_item.count}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </li>
    ))}
  </>
);

OrdersSubmenu.propTypes = {
  asPath: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      section_title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          count: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  handleOrdersSubmenuClick: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  refreshing: PropTypes.bool,
  error: PropTypes.object,
};

export default memo(OrdersSubmenu);
