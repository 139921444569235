import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FaSpinner } from "react-icons/fa";

const Spinner = ({ style, className }) => (
  <FaSpinner
    className={cx(
      "rotating fade-in-and-rotate text-master inline-block",
      className
    )}
    style={style}
  />
);

Spinner.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Spinner;
