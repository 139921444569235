import { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { PopupList } from "components";

const RenderedField = ({ value, col, row: original }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const { Cell, popup } = col;

  return (
    <div
      className={cx(
        "relative ml-3 max-w-3xl",
        popup && "cursor-pointer text-blue-600"
      )}
      onClick={popup ? () => setPopupOpen(true) : null}
      onMouseLeave={popup ? () => setPopupOpen(false) : null}
    >
      {Cell ? Cell({ value, column: col, row: { original } }) : value}
      {popupOpen && <PopupList items={popup({ original })} />}
    </div>
  );
};

RenderedField.propTypes = {
  value: PropTypes.any,
  col: PropTypes.shape({
    Cell: PropTypes.func,
    popup: PropTypes.func,
  }).isRequired,
  row: PropTypes.object.isRequired,
};

export default RenderedField;
