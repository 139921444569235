import PropTypes from "prop-types";
import cx from "classnames";
import CreatableSelect from "react-select/creatable";

const customStyles = {
  dropdownIndicator: (styles) => ({ ...styles, padding: "7px 8px" }),
  control: (styles) => ({ ...styles, minHeight: 36 }),
};

/**
 *
 * @param {array} options [{ label: "test@test.test", value: 4 }, { label: "a@b.c", value: 5 },]
 * @param {array} value [{ label: "a@b.c", value: 5 }]
 * @param {boolean} isLoading - while initial options loading, or new option being created
 * @param {function} onChange - ({label, value}) => void
 * @param {function} handleCreateOption - (newValue) => void
 * @param {boolean} isMulti - (newValue) => void
 */
const MultiSelect = ({
  className,
  styles = {},
  label,
  isLoading,
  options,
  value = [],
  disabled,
  onChange,
  handleCreateOption,
  isMulti,
  ...props
}) => {
  return (
    <label
      className={cx("inline-block w-full mt-4 text-xs", className)}
      style={styles.label}
    >
      <span className="leading-7">{label}</span>
      <CreatableSelect
        isClearable
        isDisabled={isLoading || disabled}
        isLoading={isLoading}
        onChange={onChange}
        // getNewOptionData={(value) => [{ label: value, value }]}
        onCreateOption={handleCreateOption}
        options={options}
        value={value}
        isMulti={isMulti}
        menuPlacement="auto"
        placeholder={disabled ? "" : "Select..."}
        {...props}
        styles={customStyles}
      />
    </label>
  );
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  handleCreateOption: PropTypes.func.isRequired,
  // handleCreateOption: (props, propName) => {
  //   if (
  //     props.isMulti &&
  //     (props[propName] == undefined || typeof props[propName] != "function")
  //   ) {
  //     return new Error(
  //       "handleCreateOption function must be supplied when isMulti is used"
  //     );
  //   }
  // },
  isMulti: PropTypes.bool,
};

export default MultiSelect;
