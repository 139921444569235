import PropTypes from "prop-types";
import cx from "classnames";
import { IoMdWarning } from "react-icons/io";

const Warning = ({ className }) => (
  <IoMdWarning
    className={cx("inline-block mr-2 text-lg align-middle", className)}
  />
);

Warning.propTypes = { className: PropTypes.string };

export default Warning;
