import { useState, useEffect, startTransition } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Toggle = ({
  checked,
  onChange,
  className,
  style,
  label,
  name,
  disabled,
}) => {
  const [state, setState] = useState(checked);
  useEffect(() => setState(!!checked), [checked]);
  return (
    <label
      className={cx("h-full", className, {
        "opacity-25 transition-opacity duration-500":
          disabled || checked != state,
      })}
      style={style}
    >
      <div className="toggle-container">
        <input
          type="checkbox"
          name={name}
          className="toggle-input"
          checked={state}
          onChange={(e) => {
            setState((prev) => !prev);
            startTransition(() => onChange(e));
          }}
          disabled={disabled}
        />
        <span className="toggle-slider"></span>
      </div>
      {label && <span className="ml-2 cursor-pointer">{label}</span>}
    </label>
  );
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Toggle;
