import cx from "classnames";
import PropTypes from "prop-types";

const Badge = ({ text, shading, warning, urgent, className }) => (
  <div
    className={cx(
      "h-4 px-2 py-1 text-xxs text-center leading-1 whitespace-no-wrap rounded",
      className,
      urgent
        ? "font-bold text-white bg-urgent"
        : warning
          ? "font-bold text-white bg-yellow-600"
          : shading && "font-bold bg-header-light"
    )}
  >
    {text}
  </div>
);

Badge.propTypes = {
  text: PropTypes.string,
  shading: PropTypes.bool,
  warning: PropTypes.bool,
  urgent: PropTypes.bool,
  className: PropTypes.string,
};

export default Badge;
