import { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const RadioGroup = ({ className, style, name, items, value, onChange }) => (
  <div className={cx("inline-flex items-center", className)} style={style}>
    {items.map((item, i) => (
      <Fragment key={item.label}>
        <input
          id={item.label}
          className={cx("mr-1 -mt-1 w-2 cursor-pointer", i > 0 && "ml-1")}
          type="radio"
          name={name}
          checked={value === item.value}
          onChange={() => onChange(item.value)}
        />
        <label key={item.label} htmlFor={item.label} className="cursor-pointer">
          {item.label}
        </label>
      </Fragment>
    ))}
  </div>
);

RadioGroup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroup;
