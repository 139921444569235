import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import Link from "next/link";
import Head from "next/head";
import RenderedField from "./RenderedField";
import { Spinner, Caret } from "components";

import { useQuery } from "@apollo/client";
import { getQueryName, getQueryRootField } from "utils/gqlQueries";

const DetailPage = ({
  columns,
  gqlQuery,
  gqlTransformation,
  variables,
  skip,
  title,
}) => {
  const rootField = getQueryRootField(gqlQuery);

  const { loading, data, error } = useQuery(gqlQuery, {
    displayName: getQueryName(gqlQuery),
    variables,
    skip,
    onError: (error) => console.log(error.message),
  });

  const transformedData = useMemo(() => {
    const obj = data?.[rootField];
    return obj ? gqlTransformation(obj) : null;
  }, [data, rootField, gqlTransformation]);

  const getValue = useCallback(
    (transformedData, col) =>
      typeof col.accessor === "function"
        ? col.accessor(transformedData)
        : transformedData[col.accessor],
    []
  );

  if (error)
    return (
      <h4 className="mt-12 text-center">Sorry, there was an error... 😢</h4>
    );

  if (!loading && !data?.[rootField])
    return <h2 className="text-center mt-12 text-master">No data found</h2>;

  return (
    <section className="px-10 py-6 min-h-full">
      {loading ? (
        <div className="mt-12 text-center">
          <Spinner className="text-2xl text-submenuTitle" />
        </div>
      ) : (
        <>
          <Head>
            <title>WatchMaxx Admin | {title}</title>
          </Head>
          <h2>{title}</h2>

          <ul className="text-sm mt-6">
            {columns.map((col) => (
              <li key={col.id || col.accessor.toString()} className="py-2 flex">
                {col.Header && <span className="font-bold">{col.Header}:</span>}
                <RenderedField
                  value={getValue(transformedData, col)}
                  col={col}
                  row={transformedData}
                />
              </li>
            ))}
          </ul>

          <Link href="/shipment-jobs">
            <a className="block text-blue-600 mt-12">
              <Caret height={10} dir="left" className="mr-3 fill-current" />
              All Shipment Jobs
            </a>
          </Link>
        </>
      )}
    </section>
  );
};

DetailPage.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
        .isRequired,
    }).isRequired
  ).isRequired,
  gqlQuery: PropTypes.object,
  gqlTransformation: PropTypes.func.isRequired,
  variables: PropTypes.object.isRequired,
  skip: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default DetailPage;
