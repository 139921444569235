export const defaultData = {
  ordersSubmenuSections: [
    {
      section_title: "Pending Orders",
      items: [
        {
          id: 25,
          name: "Awaiting Payment",
          count: 0,
        },
        {
          id: 26,
          name: "Payment Initiated",
          count: 0,
        },
        {
          id: 2,
          name: "Order Was Placed",
          count: 0,
        },
        {
          id: 38,
          name: "Awaiting Exchange",
          count: 0,
        },
        {
          id: 42,
          name: "Awaiting Risk Decision",
          count: 0,
        },
        {
          id: 44,
          name: "Amazon Pending",
          count: 0,
        },
      ],
    },
    {
      section_title: "New - In Stock",
      items: [
        {
          id: 18,
          name: "Review - In Stock",
          count: 0,
        },
        {
          id: 22,
          name: "Verified - In Stock",
          count: 0,
        },
      ],
    },
    {
      section_title: "New - Out of Stock",
      items: [
        {
          id: 19,
          name: "Review - Out of Stock",
          count: 0,
        },
        {
          id: 23,
          name: "Verified - Out of Stock",
          count: 0,
        },
        {
          id: 9,
          name: "Sourced - Out of Stock",
          count: 0,
        },
      ],
    },
    {
      section_title: "Suspense",
      items: [
        {
          id: 41,
          name: "Price Alert",
          count: 0,
        },
        {
          id: 27,
          name: "Payment Rejected",
          count: 0,
        },
        {
          id: 15,
          name: "Held",
          count: 0,
        },
        {
          id: 37,
          name: "Process Exchange",
          count: 0,
        },
        {
          id: 29,
          name: "Future Ship - Allocated",
          count: 0,
        },
        {
          id: 40,
          name: "Future Ship - Not Allocated",
          count: 0,
        },
        {
          id: 48,
          name: "Awaiting Warranty",
          count: 0,
        },
      ],
    },
    {
      section_title: "Processing",
      items: [
        {
          id: 28,
          name: "Awaiting Charge",
          count: 0,
        },
        {
          id: 30,
          name: "Process Shipment",
          count: 0,
        },
        {
          id: 49,
          name: "Cancellation Requested",
          count: 0,
        },
        {
          id: 36,
          name: "Process Dropship",
          count: 0,
        },
        {
          id: 31,
          name: "Shipment Processing",
          count: 0,
        },
      ],
    },
    {
      section_title: "Complete",
      items: [
        {
          id: 6,
          name: "Shipped",
          count: 0,
        },
        {
          id: 11,
          name: "Delivered",
          count: 0,
        },
        {
          id: 33,
          name: "Exchanged",
          count: 0,
        },
        {
          id: 12,
          name: "Returned",
          count: 0,
        },
      ],
    },
    {
      section_title: "Cancelled",
      items: [
        {
          id: 13,
          name: "Fraudulent",
          count: 0,
        },
        {
          id: 14,
          name: "Cancelled",
          count: 0,
        },
        {
          id: 1,
          name: "Incomplete",
          count: 0,
        },
        {
          id: 47,
          name: "Returned to Vendor",
          count: 0,
        },
      ],
    },
    {
      section_title: "RMA",
      items: [
        {
          id: 16,
          name: "RMA",
          count: 0,
        },
        {
          id: 32,
          name: "Repair",
          count: 0,
        },
        {
          id: 39,
          name: "Received",
          count: 0,
        },
        {
          id: 46,
          name: "Return Pending",
          count: 0,
        },
        {
          id: 50,
          name: "RMA Pending",
          count: 0,
        },
        {
          id: 51,
          name: "Repairing Facility - Stock Pc",
          count: 0,
        },
      ],
    },
    {
      section_title: "Miscellaneous",
      items: [
        {
          id: 34,
          name: "Multiple",
          count: 0,
        },
        {
          id: 45,
          name: "Notify When Available",
          count: 0,
        },
      ],
    },
  ],
};
