/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_by201Dcnf",
  aws_user_pools_web_client_id: "6448p5rs8ogfdhdgdulv79psto",
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsmobile;
