import { useCallback, useRef } from "react";
// import { useGetLatest } from "react-table";
import { DEBOUNCE_MS, wmaxxUrl } from "./config";

export const stopPropagation = (e) => e.stopPropagation();

export const noop = () => {};

// export const getSearchParam = (queries, name) => {
//   const obj = queries
//     .substr(1)
//     .split("&")
//     .reduce((params, param) => {
//       const [key, value] = param.split("=");
//       return { ...params, [key]: value };
//     }, {});
//   return obj[name] && obj[name].toString();
// };

export const normalizeOrderMenuName = (name) =>
  name.replace(" - Out of Stock", "").replace(" - In Stock", "");

// Copied from https://github.com/tannerlinsley/react-table/blob/master/src/publicUtils.js, because babel isn't transpiling it properly
export function useGetLatest(obj) {
  const ref = useRef();
  ref.current = obj;
  return useCallback(() => ref.current, []);
}

export function useAsyncDebounce(defaultFn, defaultWait = DEBOUNCE_MS) {
  const debounceRef = useRef({});

  const getDefaultFn = useGetLatest(defaultFn);
  const getDefaultWait = useGetLatest(defaultWait);

  return useCallback(
    async (...args) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          debounceRef.current.resolve(await getDefaultFn()(...args));
        } catch (err) {
          debounceRef.current.reject(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait]
  );
}

export const useLogWhatChanged = (name, deps, color = "yellow", skip) => {
  const ref = useRef({ ...deps });
  if (skip) return;
  const changed = Object.keys(deps).reduce(
    (acc, dep) => (ref.current[dep] !== deps[dep] ? [...acc, dep] : acc),
    []
  );
  console.log(`%c---${name}---`, `background:${color}`);
  changed.forEach((c) =>
    console.log("%cchanged: ", `background:${color}`, { [c]: deps[c] })
  );
  if (!changed.length) console.log("%c*** no changes", `background:${color}`);
  ref.current = deps;
};

export const authorized = (userGroups, allowedGroups) =>
  !allowedGroups ||
  userGroups.some(
    (group) => group === "Admin" || allowedGroups.includes(group)
  );

// export const encode = (param) => encodeURIComponent(param);
export const decode = (param) => decodeURIComponent(param);

// export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Creates Date that compensates for time zone offset, so that localized date formatting will work correctly;
// e.g. "2021-01-20" in NY becomes Date of 2021-01-20T05:00:00Z rather than 2021-01-20T00:00:00Z
// (Ignores HH:MM:SS)
export const localizedDate = (dateString) => {
  const utcDate = new Date(dateString?.split("T")[0]);
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
};

// "Mon, Apr 12, 21"
export const formatShippingDate = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "2-digit",
  weekday: "short",
}).format;

/**
 * 2021-01-20T05:00:00Z -> "1/20/2021, 12:00:00 AM"
 *
 * @param `asLocalTime` compensates for local (NY) date string incorrectly sent as UTC\
 *  e.g. 2021-01-20T05:00:00Z -> "1/20/2021, 05:00:00 AM"
 */
export const ISODateToNYLocaleString = (date, asLocalTime) =>
  new Date(date).toLocaleString("en-US", {
    timeZone: asLocalTime ? "UTC" : "America/New_York",
  });

// 2021-01-20T04:00:00Z -> "1/19/2021"
export const ISODateToNYLocaleDateString = (date) =>
  new Date(date).toLocaleDateString("en-US", { timeZone: "America/New_York" });

// 2021-01-20T04:00:00Z -> "2021-01-19"
export const ISODateToNYTimezoneYYYYMMDD = (date) =>
  new Date(date)
    .toLocaleDateString("en-GB", {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");

// 2022-01-01T00:00:00+2:00 -> "2022-01-01T00:00:00Z"
export const localDateAsStringWithoutTimezone = (date) =>
  date.toISOString().slice(0, 23) + "Z";

// "2024-01-30T12:00:00"
export const currentNYDateAsISOStringWithoutTimezone = () =>
  new Date()
    .toLocaleString("sv-SE", {
      timeZone: "America/New_York",
    })
    .replace(" ", "T");

export const DAY_MS = 1000 * 60 * 60 * 24;
export const WARNING = 1,
  URGENT = 2;

export const getShippingUrgency = (date) => {
  const now = localizedDate(ISODateToNYTimezoneYYYYMMDD(new Date()));
  const shipDate = localizedDate(date);
  const diff = (now - shipDate) / DAY_MS;
  if (diff > 1.1) return URGENT; // use decimal to account for DST
  if (diff > 0) return WARNING;
};

export const capitalize = (str = "") => str[0].toUpperCase() + str.slice(1);

export const titleCase = (str = "") => str.split(" ").map(capitalize).join(" ");

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const formatCurrency = (amt) => formatter.format(amt);

export const optionFromValue = (str) => (str ? { label: str, value: str } : null);

export const optionSortingFn = (a, b) => {
  const l1 = a.label.toUpperCase();
  const l2 = b.label.toUpperCase();
  return l1 < l2 ? -1 : l1 > l2 ? 1 : 0;
};

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const isValidEmail = (email) => emailRegex.test(email || "");

export const emailFromBrackets = (text) =>
  text.match(/^(?:.+ ?<)?(.+?)>?$/)?.[1];

export const errorNoise = () => new Audio("/audio/error654765.wav").play();

export const getOrderDetailLink = (orderId) =>
  `${wmaxxUrl}?cmd=order&type=&order_id=${orderId}`;

export const getShipmentDetailLink = (shipmentId) =>
  `${wmaxxUrl}?cmd=shipment_detail&shipmentid=${shipmentId}`;
