import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Panel = ({ title, className, style, children }) => (
  <div className={cx("px-6 pb-6 flex-1 border", className)} style={style}>
    <h3 className="mb-6 text-xs font-bold uppercase">{title}</h3>
    {children}
  </div>
);

Panel.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any.isRequired,
};

export default Panel;
