import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const PopupList = ({ items, className, style }) => (
  <ul
    className={cx(
      "absolute px-3 py-2 whitespace-no-wrap border rounded shadow-lg text-master bg-gray-100 z-10",
      className
    )}
    style={style}
  >
    {items}
  </ul>
);

PopupList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default PopupList;
