import { useState, useContext, memo, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import cx from "classnames";
import { menu, menuIcons, parentPathMapping } from "../menu";
import SubmenuItem from "./SubmenuItem";
import OrdersSubmenu from "./OrdersSubmenu";
import { FaEdit } from "react-icons/fa";
import { AiFillRightCircle } from "react-icons/ai";
import { defaultData } from "utils/defaultData";
import { wmaxxUrl } from "utils/config";
import { authorized } from "utils/utils";
import { Context } from "../Context";

import { useQuery } from "@apollo/client";
import { ORDERS_SUBMENU } from "utils/gqlQueries";

const Sidebar = ({ className, style }) => {
  const router = useRouter();
  const { pathname, asPath } = router;
  const path = pathname.slice(1);
  //   const currentCmd = getSearchParam(asPath.slice(1), "cmd") || "orders";
  // const currentPage = pathname.slice(1); // || query.cmd; // specify default page
  const context = useContext(Context);

  const { loading, data, error, refetch } = useQuery(ORDERS_SUBMENU, {
    // ssr: false, // this doesn't always prevent ssr loading!
    skip: typeof window === "undefined",
    notifyOnNetworkStatusChange: true,
    displayName: "ordersSubmenuQuery",
  });

  context.refreshOrdersSubmenu.current = refetch;

  const [openMenus, setOpenMenus] = useState([path]);

  useEffect(() => {
    setOpenMenus([parentPathMapping[path] || path]);
  }, [path]);

  const handleMenuClick = (e, item) => {
    // items without "path" get sent to wmaxx via the <a> link
    if (item.path) {
      e.preventDefault();
      router.push(`/${item.path}${item.params || ""}`);
      // setOpenMenus([item.path]);
    }
  };

  const handleToggleMenu = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenus((prev) => {
      if (prev.includes(item.path || item.cmd)) {
        // To allow opening multiple:
        // return prev.filter((m) => m !== item.name);
        return [];
      } else {
        // return [...prev, item.name];
        return [item.path || item.cmd];
      }
    });
  };

  const handleOrdersSubmenuClick = (e, item) => {
    e.preventDefault();
    router.push(`/orders?status_id=${item.id}`);
  };

  const filteredMenu = useMemo(
    () =>
      menu
        .filter((item) => authorized(context.userGroups, item.allow))
        .map((item) => ({
          ...item,
          submenu:
            item.submenu?.filter((i) =>
              authorized(context.userGroups, i.allow)
            ) || [],
        })),
    [context.userGroups]
  );

  return (
    <aside className={cx(className, "hide-scrollbar")} style={style}>
      <ul className="min-h-full pt-8 bg-sidebar-light">
        {filteredMenu.map((item) => (
          <li key={item.name} className="">
            <a
              href={
                item.path
                  ? item.path + (item.params || "")
                  : `${wmaxxUrl}?cmd=${item.cmd}${item.params || ""}`
              }
              className="relative flex h-12 pl-8 pr-6 leading-10 text-sidebarText hover:text-white"
              onClick={(e) => handleMenuClick(e, item)}
            >
              {(item.name === "Orders" || item.submenu.length > 0) && (
                <AiFillRightCircle
                  className={cx(
                    "absolute left-0 w-5 h-5 ml-1 text-sidebar-dark hover:text-sidebar-dark transition-transform duration-300",
                    {
                      "transform rotate-90": openMenus.includes(
                        item.path || item.cmd
                      ),
                    }
                  )}
                  style={{ top: 10 }}
                  onClick={(e) => handleToggleMenu(e, item)}
                />
              )}
              <div
                className={
                  item.matcher?.(asPath) ||
                  asPath === `/${item.path}${item.params || ""}`
                    ? "text-blue-400 hover:text-blue-300"
                    : "text-sidebarText hover:text-white"
                }
              >
                {item.name}
              </div>
              <div className="flex justify-center items-center h-10 w-10 ml-auto bg-sidebar-dark">
                {menuIcons[item.name] || <FaEdit />}
              </div>
            </a>

            {(item.name === "Orders" || item.submenu.length > 0) && (
              <ul
                className={cx(
                  "max-h-0 pt-0 m-0 overflow-hidden transition-all duration-500 text-smaller bg-sidebar-dark",
                  {
                    "pt-5 mb-2 max-h-2000": openMenus.includes(
                      item.path || item.cmd
                    ),
                  }
                )}
              >
                {item.submenu.map((i) => (
                  // Regular submenu
                  <SubmenuItem
                    key={i.name}
                    submenuItem={i}
                    menuItem={item}
                    active={
                      i.matcher?.(asPath) ||
                      asPath === `/${i.path}${i.params || ""}`
                    }
                  />
                ))}

                {item.name === "Orders" && (
                  <OrdersSubmenu
                    sections={
                      // **TODO: fetch initial data server-side
                      data?.sections || defaultData.ordersSubmenuSections
                    }
                    asPath={asPath}
                    handleOrdersSubmenuClick={handleOrdersSubmenuClick}
                    handleRefresh={() => refetch()}
                    refreshing={loading}
                    error={error}
                  />
                )}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default memo(Sidebar);
