import { MdCancel } from "react-icons/md";

const ClearIcon = (props) => (
  <MdCancel className="inline mr-1 text-sm" {...props} />
  // <div className="inline-flex items-center justify-center h-3 w-3 mr-1 text-white bg-blue-600 rounded-full text-xs leading-none">
  //   ×
  // </div>
);

export default ClearIcon;
