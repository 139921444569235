import { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Auth, Hub } from "aws-amplify";
import { AuthState } from "@aws-amplify/ui-components";
import { TextField, Button, Spinner } from "components";

const SignIn = ({ slot, authState, showLayout }) => {
  const [signinFields, setSigninFields] = useState({
    username: "",
    password: "",
    error: null,
  });
  const { username, password, error } = signinFields;

  const [submitting, setSubmitting] = useState(false);

  const signIn = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const { username, password } = signinFields;
      const user = await Auth.signIn(username, password);
      const { challengeName } = user;
      // console.log("SignIn.jsx > signIn", { user, challengeName });
      if (challengeName) {
        Hub.dispatch("UI Auth", {
          event: "AuthStateChange",
          message:
            challengeName === "SOFTWARE_TOKEN_MFA"
              ? AuthState.ConfirmSignIn
              : AuthState.ResetPassword, // challengeName === NEW_PASSWORD_REQUIRED
          data: user,
        });
      }
    } catch (e) {
      let error = e.message || "Authentication error";
      if (error.startsWith("Custom auth")) error = "Please enter password";
      if (error === "Password attempts exceeded") {
        error += "; please wait a few minutes";
      }

      setSigninFields((prev) => ({ ...prev, error }));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      className={cx(
        "fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center",
        !authState && "hidden"
      )}
      slot={slot}
    >
      <form
        className="flex flex-col py-10 px-20 bg-white shadow-2xl rounded"
        style={
          !showLayout
            ? { width: 300, height: 320, padding: 20, margin: 20 }
            : { width: 430 }
        }
        onSubmit={signIn}
      >
        {showLayout && (
          <h3
            className="text-sidebar-dark"
            style={{ textShadow: "1px 1px 3px #888" }}
          >
            WatchMaxx Login
          </h3>
        )}
        <TextField
          label="Username"
          placeholder="Enter your username"
          name="username"
          onChange={({ target: { value } }) =>
            setSigninFields((prev) => ({
              username: value,
              password: prev.password,
            }))
          }
          value={username}
        />
        <TextField
          label="Password"
          placeholder="Enter your password"
          name="password"
          type="password"
          onChange={({ target: { value } }) =>
            setSigninFields((prev) => ({
              password: value,
              username: prev.username,
            }))
          }
          value={password}
        />
        <Button theme="blue" className="relative mt-10 w-full">
          <>
            <span>Sign In</span>
            {submitting && (
              <Spinner
                className="absolute right-0 mr-5"
                style={{ top: "calc(50% - 7px)" }}
              />
            )}
          </>
        </Button>

        <div className="mt-2 h-10 text-red-600">{error}</div>
      </form>
    </div>
  );
};

SignIn.propTypes = {
  slot: PropTypes.string.isRequired,
  authState: PropTypes.string,
  showLayout: PropTypes.bool.isRequired,
};

export default SignIn;
