import cx from "classnames";
import PropTypes from "prop-types";

const Caret = ({ dir, className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.96 50.97"
      className={cx(
        "inline-block",
        className,
        dir === "left" && "transform rotate-180"
      )}
      {...rest}
    >
      <path
        d="M4.94,49.16,28.63,27.43a1.18,1.18,0,0,0,.5-.3A2.86,2.86,0,0,0,30,25a2.83,2.83,0,0,0-.83-2.14,1.17,1.17,0,0,0-.52-.31L5,.86a2.9,2.9,0,0,0-4.1,0A2.94,2.94,0,0,0,0,2.92,2.92,2.92,0,0,0,.9,5L22.7,25,.87,45a2.91,2.91,0,0,0,2.06,5A2.8,2.8,0,0,0,4.94,49.16Z"
        transform="translate(0.49 0.48)"
      />
    </svg>
  );
};

Caret.propTypes = {
  dir: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
};

export default Caret;
