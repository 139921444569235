import { memo, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Toggle } from "components";

const Tabs = ({ tabsProps: { tabs, onTabSelect, activeId } }) => {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => setShowAll(!!window.localStorage.getItem("showAllTabs")), []);

  const hasHidden = useMemo(() => tabs.some((t) => t.hidden), [tabs]);

  const isHidden = useMemo(
    () => tabs.find((t) => t.id === activeId).hidden,
    [tabs, activeId]
  );

  const filteredTabs = useMemo(() => {
    if (hasHidden && !showAll && !isHidden)
      return tabs.filter((t) => !t.hidden);

    return tabs;
  }, [tabs, hasHidden, showAll, isHidden]);

  return (
    <div className="bg-white">
      <nav className="flex whitespace-no-wrap">
        {filteredTabs.map((el) => (
          <button
            key={el.id}
            className={cx(
              "w-32 py-4 px-2 block hover:text-blue-500 focus:outline-none",
              activeId === el.id ? "text-blue-500 bg-light" : "text-gray-600",
              activeId === el.id && "border-b-2 font-medium border-blue-500"
            )}
            onClick={() => onTabSelect(el.id)}
          >
            {el.name}
          </button>
        ))}
        {hasHidden && (
          <Toggle
            label="Show All"
            checked={showAll}
            disabled={isHidden}
            className={cx("ml-auto mr-1 mt-5", !showAll && "opacity-75")}
            onChange={() => {
              setShowAll((prev) => {
                window.localStorage.setItem("showAllTabs", prev ? "" : 1);
                return !prev;
              });
            }}
          />
        )}
      </nav>
    </div>
  );
};

Tabs.propTypes = {
  tabsProps: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    onTabSelect: PropTypes.func.isRequired,
    activeId: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Tabs);
