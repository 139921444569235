import { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Context } from "pageFiles/App/Context";
import { ALERT_TIMEOUT } from "utils/config";

const Alert = ({
  title,
  text,
  type = "error",
  timeout = ALERT_TIMEOUT,
  onClose,
}) => {
  const { setAlertInfo } = useContext(Context);
  const [fading, setFading] = useState(false);

  const close = useCallback(() => {
    setAlertInfo();
    onClose?.();
  }, [setAlertInfo, onClose]);

  useEffect(() => {
    const id = setTimeout(() => setFading(true), timeout - 700);
    const id2 = setTimeout(close, timeout);
    return () => {
      clearTimeout(id);
      clearTimeout(id2);
    };
  }, [timeout, close]);

  return (
    <div
      className={cx(
        "fixed px-4 py-3 border rounded shadow-lg transition-opacity duration-700",
        type === "error" && "bg-red-100 text-red-700 border-red-400",
        type === "info" && "bg-blue-100 text-blue-700 border-blue-400",
        fading && "opacity-0"
      )}
      style={{
        top: 6,
        left: "50%",
        transform: "translate(-50%, 0)",
        minWidth: "min(100vw, 400px)",
        maxWidth: 600,
        zIndex: 1000,
      }}
      role="alert"
    >
      {title && <strong className="font-bold">{title} </strong>}
      <span className="block mt-1 whitespace-pre-line">{text}</span>
      <span
        className="absolute top-0 bottom-0 right-0 px-1 py-1"
        onClick={close}
      >
        <svg
          className={cx(
            "fill-current h-6 w-6",
            type === "error" && "text-red-500",
            type === "info" && "text-blue-500"
          )}
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(["error", "info"]),
  timeout: PropTypes.number,
  onClose: PropTypes.func,
};

export default Alert;
