import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { wmaxxUrl } from "utils/config";
import {
  authorized,
  getShipmentDetailLink,
  getOrderDetailLink,
} from "utils/utils";
import { ExternalLink } from "components";
import BarcodeLinks from "./BarcodeLinks";

const SearchLinks = ({ searchText, clear, userGroups }) => {
  const [barcodeToShow, setBarcodeToShow] = useState();

  const validNumberForSearch = (min = 99, max = 999999) =>
    Number(searchText) > min && Number(searchText) < max;

  const links = [
    {
      text: "Order #",
      link: getOrderDetailLink(searchText),
      valid:
        authorized(userGroups, ["Orders", "Orders_Full"]) &&
        validNumberForSearch(11525),
    },
    {
      text: "Shipment #",
      link: getShipmentDetailLink(searchText),
      valid:
        authorized(userGroups, ["Shipments", "Shipments_Full"]) &&
        validNumberForSearch(5287),
    },
    {
      text: "Return / Repair #",
      link: `${wmaxxUrl}?cmd=returns_detail&returns_id=${searchText}`,
      valid:
        authorized(userGroups, ["Returns"]) && validNumberForSearch(1, 99999),
    },
    {
      text: "Trade-in #",
      link: `${wmaxxUrl}?cmd=tradein&command=item&id=${searchText}`,
      valid:
        authorized(userGroups, ["TradeIn", "TradeIn_Full"]) &&
        validNumberForSearch(33),
    },
    {
      text: "Barcode # ",
      link: "#",
      fn: () => setBarcodeToShow(searchText),
      valid:
        authorized(userGroups, ["Inventory", "Inventory_Full"]) &&
        validNumberForSearch(1, 9999999),
    },
    {
      text: "P.O. #",
      link: `${wmaxxUrl}?cmd=purchasing&command=purchaseorderform&id=${searchText}`,
      valid:
        authorized(userGroups, ["Purchasing", "Purchasing_Full"]) &&
        validNumberForSearch(1),
    },
    {
      text: "Bill #",
      link: `${wmaxxUrl}?cmd=purchasing&command=billform&id=${searchText}`,
      valid:
        authorized(userGroups, ["Purchasing", "Purchasing_Full"]) &&
        validNumberForSearch(85),
    },
    {
      text: "Order search:",
      link: `${wmaxxUrl}?search_type=orders&order_field=${searchText}&cmd=orders&command=search&days=180`,
      quotes: true,
      valid:
        authorized(userGroups, ["Orders", "Orders_Full"]) &&
        searchText.length >= 3,
    },
    {
      text: "Item search:",
      link: `${wmaxxUrl}?search_type=inventory&order_field=${searchText}&cmd=orders&command=search`,
      quotes: true,
      valid:
        authorized(userGroups, ["Inventory", "Inventory_Full"]) &&
        searchText.length >= 3,
    },
  ];

  const resetBarcode = useCallback(() => setBarcodeToShow(), []);

  useEffect(() => {
    resetBarcode();
  }, [searchText, resetBarcode]);

  return (
    searchText.length >= 3 &&
    searchText.length < 40 && (
      <div
        className={cx(
          "flex h-40 py-3 px-4 absolute border shadow-lg bg-white z-30 overflow-hidden",
          "font-bold text-xs leading-6 whitespace-no-wrap"
        )}
        style={{ top: "100%", minWidth: "25rem" }}
      >
        <ul className="h-full w-full flex flex-col flex-wrap text-blue-600">
          {links.map((s) => {
            return (
              <li key={s.text} className="w-1/2 truncate">
                {s.valid ? (
                  <ExternalLink
                    href={s.link}
                    onClick={(e) => {
                      if (s.fn) {
                        e.preventDefault();
                        return s.fn();
                      }
                      clear();
                    }}
                  >
                    {s.text} {s.quotes ? `“${searchText}”` : searchText}{" "}
                  </ExternalLink>
                ) : (
                  <span className="opacity-75 text-submenuTitle" tabIndex={-1}>
                    {s.text}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
        <BarcodeLinks
          barcode={barcodeToShow}
          reset={resetBarcode}
          onSelect={clear}
        />
      </div>
    )
  );
};

SearchLinks.propTypes = {
  searchText: PropTypes.string,
  clear: PropTypes.func.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SearchLinks;
