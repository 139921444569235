import { memo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

const ExternalLink = ({ href, children, disabled, className, ...rest }) => {
  return disabled ? (
    <span className={className}>{children}</span>
  ) : (
    <a
      className={cx("text-blue-600", className)}
      href={href}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(ExternalLink);
