import { createContext } from "react";
import PropTypes from "prop-types";

export const Context = createContext({});

const ContextProvider = ({ children, ...rest }) => {
  return <Context.Provider value={{ ...rest }}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  scrollMainToTop: PropTypes.func.isRequired,
};

export { ContextProvider };
