import { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { FaSignOutAlt } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { GiSpiderWeb } from "react-icons/gi";
import { wmaxxUrl, graphqlUri } from "utils/config";
import { authorized, getOrderDetailLink } from "utils/utils";
import { Context } from "pageFiles/App/Context";
import { oldBackEndUrl } from "pageFiles/App/menu";
import SearchLinks from "./SearchLinks";

const isProd = process.env.NEXT_PUBLIC_ENV_NAME === "prod";

const Header = ({ className, toggleMinimized, isMobile }) => {
  const { signOut, userGroups } = useContext(Context);

  const { asPath } = useRouter();

  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("orders");

  const handleSearch = () => {
    if (searchText) {
      const encodedText = encodeURIComponent(searchText.trim());
      const url =
        searchType === "orderId"
          ? getOrderDetailLink(encodedText)
          : `${wmaxxUrl}?search_type=${searchType}&order_field=${encodedText}&cmd=orders&command=search&days=180`;
      window.open(url, "_blank");
    }
  };

  const clearSearch = useCallback(() => setSearchText(""), []);

  return (
    <nav className={className}>
      <div
        className="absolute ml-3 left-0 text-xl opacity-75 hover:opacity-100 cursor-pointer"
        onClick={toggleMinimized}
      >
        ☰
      </div>
      <div className="w-48">
        <img src="/images/logo-2.png" alt="wm_logo" width={136} height={22} />
      </div>

      {!isMobile && (
        <>
          <IoIosSearch
            className="inline-block"
            style={{ fontSize: "1.2rem" }}
          />

          <div className="search-arrow">
            <select
              className="h-9 ml-4 pl-3 pr-5 py-1 text-sm bg-light border appearance-none rounded-none outline-none"
              name="search"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
            >
              {/* <option value="orderId">Order #</option> */}
              {authorized(userGroups, ["Orders"]) && (
                <option value="orders">Orders</option>
              )}
              {authorized(userGroups, ["Inventory"]) && (
                <option value="inventory">Inventory</option>
              )}
            </select>
          </div>
          <div className="relative flex">
            <input
              type={searchType === "orderId" ? "number" : "search"}
              className="h-9 px-3 py-2 tracking-wide border border-gray-500 outline-none hide-number-arrows"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
            <SearchLinks
              searchText={searchText}
              clear={clearSearch}
              userGroups={userGroups}
            />
            <button
              className="h-9 px-4 py-1 cursor-pointer bg-light border border-gray-500 rounded-r focus:outline-none"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </>
      )}

      {!isProd && graphqlUri.includes("admin-api.") && (
        <div className="ml-5 text-red-600 font-bold">** USING LIVE API **</div>
      )}

      <div className="ml-auto flex">
        {!isMobile && (
          <a href={oldBackEndUrl(asPath)}>
            <div className="mr-6 text-xs text-submenuTitle opacity-75 flex items-center cursor-pointer">
              <GiSpiderWeb />
              &nbsp;Old back end
            </div>
          </a>
        )}

        <a
          href={`${wmaxxUrl}?cmd=logout`}
          onClick={(e) => {
            e.preventDefault();
            signOut();
          }}
          className="text-xs"
        >
          <FaSignOutAlt className="inline-block mr-1 mb-1" />
          Logout
        </a>
      </div>
    </nav>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  toggleMinimized: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default Header;
