import { cloneElement, useState, useEffect, memo } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import cx from "classnames";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { Alert } from "components";
import { pageInfo } from "pageFiles/App/menu";

const printSvgStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

const isMobileWidth = () => window.matchMedia("(max-width: 1023px)").matches;

const Layout = ({ mainRef, children, alertInfo, showLayout }) => {
  const { pathname, asPath } = useRouter();
  const [minimized, setMinimized] = useState(false);
  const [isMobile, setIsMobile] = useState();
  // console.log("layout", orders_submenu, ordersSubmenu);
  const title =
    pageInfo.titles[asPath.slice(1)] ||
    pageInfo.titles[pathname.slice(1)] ||
    children.type.title;

  useEffect(() => {
    if (isMobileWidth()) {
      setMinimized(true);
      setIsMobile(true);
    }
  }, []);

  if (pathname === "/login" || !showLayout)
    return (
      <>
        {alertInfo && <Alert {...alertInfo} key={alertInfo.key} />}
        {children}
      </>
    );
  // The login page just reroutes to wmaxx or /orders

  return (
    <div className="layout-grid">
      <Head>
        <title>{`WatchMaxx Admin${title ? ` | ${title}` : ""}`}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {alertInfo && <Alert {...alertInfo} key={alertInfo.key} />}
      <Header
        className="flex items-center col-span-2 pl-10 pr-5 bg-white border-b border-gray-200"
        toggleMinimized={() => setMinimized((prev) => !prev)}
        isMobile={isMobile}
      />

      <Sidebar
        className={cx(
          "overflow-y-scroll",
          minimized ? "max-w-0" : "min-w-xxs max-w-xs"
        )}
        style={{ transition: "max-width 500ms" }}
        // ordersSubmenuLoading={loading}
        // ordersSubmenu={data?.sections || []}
      />

      <main className="overflow-auto" ref={mainRef}>
        {cloneElement(children /* { ordersSubmenu }*/)}
      </main>

      <iframe className="hidden" id="print-frame" />
      <div className="hidden">
        <div style={printSvgStyle}>
          <svg id="print-svg" />
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  mainRef: PropTypes.object.isRequired,
  children: PropTypes.node,
  alertInfo: PropTypes.shape({
    ...Alert.propTypes,
    key: PropTypes.any.isRequired,
  }),
  showLayout: PropTypes.bool.isRequired,
};

export default memo(Layout);
